import * as React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import { NavLink } from 'react-router-dom';
import Favicon from '../../image/Favicon.png'
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';


const Navigation = () => {
  return (
    <nav className="navbar base-bg-color top-0 d-flex justify-content-center align-items-center flex-column">
    <img src={Favicon} width="50" height="50" style={{objectFit:"cover"}}/>
    <a className="navbar-brand base-font-bold fs-4 text-white border-bottom-2">TechieBears</a>
    {/* <a className="navbar-brand">Techie Bears</a> */}
</nav>
  )
}

export default Navigation







