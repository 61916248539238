import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import UserLogin from "./components/auth/UserLogin";
import SideBar from "./components/Layout/sidebar/SideBar";
import UserAttendance from "./components/pages/UserAttendance";
import ProfileModal from "./components/ProfileModal";
import Mispunchdetails from "./components/UserMispunch";
import JoiningLetter from './components/pages/joiningletter';
import UserDocuments from './components/pages/udocuments';
import LeavePage from './components/pages/Leave/LeavePage';
import AddBankDetails from './components/pages/Basic_Details/AddBankDetails';
import AddExperience from './components/pages/Basic_Details/AddExperience';
import Holidays from './components/pages/Leave/holidays';
import { useSelector, useDispatch } from "react-redux"
import DashBoard from './components/Dashboard';
import RewardsPage from './components/Rewards';
import DetailsNavigation from './components/pages/Basic_Details/DetailsNavigation';
import EditBasicDetails from './components/pages/Basic_Details/EditBasicDetails';
import AddFamilyDetails from './components/pages/Basic_Details/AddFamilyDetails';
import SalarySlip from './components/pages/UserSalarySlip/SalarySlip';
import UserSalarySlipTable from './components/pages/UserSalarySlip/UserSalarySlipTable';










function App(props: any) {
  const Logged = useSelector((state: any) => state?.root?.reducers?.loggedIn);


  return (
    <>
      {
        Logged ?
          <SideBar>
            <Routes >
              <Route path="/" element={<DashBoard />} />
              <Route path="/attendance" element={<UserAttendance />} />
              <Route path="/profilemodal" element={<ProfileModal />} />
              <Route path="/usermispunch" element={<Mispunchdetails />} />
              <Route path="/joiningletter" element={<JoiningLetter />} />
              <Route path="/basicdetails/editbasicdetails" element={<EditBasicDetails />} />
              <Route path="/basicdetails/addexperience" element={<AddExperience />} />
              <Route path="/basicdetails/editfamily" element={<AddFamilyDetails />} />
              <Route path="/basicdetails/editbankdetails" element={<AddBankDetails />} />
              <Route path="/basicdetails" element={<DetailsNavigation />} />
              <Route path="/userdocument" element={<UserDocuments />} />
              <Route path="leave" element={<LeavePage />} />
              <Route path="holidays" element={<Holidays />} />
              <Route path="dashboard" element={<DashBoard />} />
              <Route path="rewards" element={<RewardsPage />} />
              <Route path="usersalarysliptable" element={<UserSalarySlipTable />} />

            </Routes>
          </SideBar>
          :
          <Routes >
            <Route path="/" element={<UserLogin />} />
            

          </Routes>
      }
    </>
  );
}
export default App;