import React, { useEffect, useState,useRef } from 'react'
import { Link } from 'react-router-dom'
import Favicon from '../../../image/Favicon.png'
import dashboardIcon from '../../../image/grid.svg'
import attendanceIcon from '../../../image/calendar.svg'
import attendanceIconBlack from '../../../image/calendar-black.svg'
import leavesIcon from '../../../image/leaves.svg'
import MispunchIcon from '../../../image/mispunch.svg'
import basicdetailsIcon from '../../../image/user.svg'
import holidaysIcon from '../../../image/briefcase.svg'
import slipIcon from '../../../image/file-text.svg'
import monthly from '../../../image/monthly.svg'
import RewardsIcon from '../../../image/gift.svg'
import DownIcon from '../../../image/down.svg'
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import ProfileModal from '../../ProfileModal';
import NavDropdown from 'react-bootstrap/NavDropdown';
import RewardsModal from '../../pages/rewardsModal';
import { environment } from '../../../env';
import '../sidebar/sidebar.css'
import addNotification from 'react-push-notification'
import logo from '../../../image/logo.png'

interface UserData {
    firstname: string;
    lastname: string;
    profile_pic: string;
    rewards: number
}
const SideBar = ({ children }: any) => {
    const [userDetails, setUserDetails] = useState<UserData>({
        firstname: '',
        lastname: '',
        profile_pic: '',
        rewards: 0
    });
    const [update, setUpdate] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [Aopen, setAOpen] = useState(false);
    const [Sopen, setSOpen] = useState(false);
    const Atogg = () => { setAOpen(!Aopen) }
    const Stogg = () => { setSOpen(!Sopen) }


    let userId = localStorage.getItem("userid");
    let EmpId = localStorage.getItem("employee_id");

    const refOne:any = useRef()
    const EmployeeDetails = () => {
        axios.get(`${environment.baseUrl}user-details/${EmpId}`)
            .then(res => {
                setUserDetails(res.data);
                // setLatestPost(res.data[0])
            })
            .catch(res => console.log("error",res))
    };

    useEffect(() => {
        EmployeeDetails()
    }, [update])
  
    useEffect(() => {
        const sidebarToggle:any = (event:any) =>{
            event.stopPropagation();
            if (refOne.current.contains(event.target)) {

                setIsActive(isActive)
               
            }
        }
      document.addEventListener("mousedown",sidebarToggle)
    
      return () => {
        document.removeEventListener("mousedown",sidebarToggle)
      }
    }, [])
    
    return (
        <>

            <div id="wrapper" >
                <ul
                    className={isActive ? `sidebar-width navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ` : `sidebar-width-100 navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ` }
                    id="accordionSidebar"
                   >
                    {/* Sidebar - Brand */}
                    <div
                        className="px-3  d-flex align-items-center justify-content-between border-bottom text-left"
                       
                    >
                        <div className="py-2 m-0 w-100">
                            <Link to="dashboard">
                            <img
                            className=" rounded-circle w-25"
                            src={Favicon}
                        />
                            </Link>
                       
                        </div>
                         <button
                                id="sidebarToggleTop"
                                className="bg-transparent outline-none border-0 text-white fs-2 "
                                onClick={()=>setIsActive(!isActive)}
                            >
                               <i className="fa-solid fa-xmark"></i>
                            </button>
                    
                    </div>
                    <li className="nav-item mt-3  active">
                        <Link className="nav-link d-flex align-items-center justify-content-start ms-3 my-2" to="dashboard">
                           <img src={dashboardIcon} alt="" />
                            <h5 className="text-white fs-5 ps-2 mb-0 base-font-bold">DashBoard</h5>
                        </Link>
                        <Link className="nav-link d-flex align-items-center justify-content-start ms-3 my-2" to="#" data-toggle={Atogg} data-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages" onClick={Atogg}>
                        <img src={attendanceIcon} alt="" />
                            <h5 className="text-white fs-5 ps-2 mb-0 base-font-bold">Attendance
                            <img src={DownIcon} alt="" className="ps-2" />
                            </h5>

                        </Link>
                        {Aopen ? <>
                            <div id="collapsePages" className="collapse show" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded" style={{ color: "black" }}>
                                    <Link className="nav-link d-flex align-items-center justify-content-start ps-4 pb-3" to="attendance">
                                    <img src={attendanceIconBlack} alt="" className="w-h-20" />
                                    <h6 className="text-black fs-6 ps-3 mb-0 base-font-600">Attendance</h6>
                                    </Link>
                                    <Link className="nav-link d-flex align-items-center justify-content-start ps-4 pb-3" to="leave" >
                                    <img src={leavesIcon} alt="" className="w-h-20" />
                                        <h6 className="text-black fs-6 ps-3 mb-0 base-font-600">Leaves</h6>
                                    </Link>
                                    <Link className="nav-link d-flex align-items-center justify-content-start ps-4 pb-3" to="usermispunch" >
                                    <img src={MispunchIcon} alt="" className="w-h-20" />
                                        <h6 className="text-black fs-6 ps-3 mb-0 base-font-600">Mispunch</h6>
                                    </Link>
                                </div>
                            </div>
                        </> : <></>}
                    </li>





                    <li className="nav-item active">
                        <Link className="nav-link d-flex align-items-center justify-content-start ms-3 my-2" to="basicdetails">
                        <img src={basicdetailsIcon} alt="" />
                        <h5 className="text-white fs-5 ps-2 mb-0 base-font-bold">Basic Details</h5>
                        </Link>
                    </li>



                    <li className="nav-item active">
                        <Link className="nav-link d-flex align-items-center justify-content-start ms-3 my-2" to="holidays">
                        <img src={holidaysIcon} alt="" />
                        <h5 className="text-white fs-5 ps-2 mb-0 base-font-bold">Holidays</h5>
                        </Link>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link d-flex align-items-center justify-content-start ms-3 my-2" href="#" data-toggle={Stogg} data-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages" onClick={Stogg}>
                        <img src={slipIcon} alt="" />
                            <h5 className="text-white fs-5 ps-2 mb-0 base-font-bold">Salary Slips  <img src={DownIcon} alt="" className="ps-2" /></h5>
                        </a>
                        {Sopen ? <>
                            <div id="collapsePages" className="collapse show" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded" style={{ color: "black" }}>
                                    <Link className="nav-link d-flex align-items-center justify-content-start ps-4 pb-3" to="usersalarysliptable">
                                    <img src={monthly} alt="" className="w-h-20" />
                                        <h6 className="text-black fs-6 ps-3 mb-0 base-font-600">Monthly</h6>
                                    </Link>
                                   

                                </div>
                            </div>
                        </> : <></>}
                    </li>
                    <li className="nav-item active">
                        <Link className="nav-link d-flex align-items-center justify-content-start ms-3 my-2" to="rewards">
                            {/* {/ <i  className="fa-light fa-treasure-chest"></i> /} */}
                            <img src={RewardsIcon} alt="" />
                            <h5 className="text-white fs-5 ps-2 mb-0 base-font-bold">Rewards</h5>
                        </Link>
                    </li>
                    <li className="nav-item active">
                        <Link className="nav-link d-flex align-items-center justify-content-start ms-3 my-2" to="notification">
                            {/* {/ <i  className="fa-light fa-treasure-chest"></i> /} */}
                            <img src={RewardsIcon} alt="" />
                            <h5 className="text-white fs-5 ps-2 mb-0 base-font-bold">Notification</h5>
                        </Link>
                    </li>


                </ul>
                <div id="content-wrapper" className="d-flex flex-column">

                    <div id="content">

                        <nav className="navbar navbar-expand navbar-light bg-white topbar static-top shadow">

                            <button
                                id="sidebarToggleTop"
                                className="bg-transparent outline-none border-0 text-white fs-3 ps-4 text-primary"
                                onClick={()=>setIsActive(!isActive)}
                            >
                               <i className="fa-solid fa-bars-staggered"></i>
                            </button>




                            <ul className="navbar-nav ml-auto">
                                <div className="topbar-divider d-none d-lg-block" />

                                <li className="nav-item dropdown no-arrow">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="userDropdown"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <span className="mr-2 d-none d-md-inline text-dark small text-capitalize fs-6 base-font-600 mb-0">
                                            <span>{update}</span>
                                            {userDetails.firstname} {userDetails.lastname}

                                        </span>
                                        <ProfileModal profile={userDetails.profile_pic} />
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <div className="container-fluid-main"  ref={refOne} >
                        {children}
                        </div>
                    </div>
                </div>
            </div>
            {/* <footer
                style={{ position: "fixed", left: 224, bottom: -24, width: "86%", textAlign: "center" }}
                className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright © TechieBears 2022</span>
                    </div>
                </div>
            </footer> */}
        </>
    )
}

export default SideBar