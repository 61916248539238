import React, { useState, useRef, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import { Select } from '@mui/material';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import axios from 'axios';
import { environment } from '../../env';
import { useForm } from 'react-hook-form';

interface UserData {
    firstname: any;
    lastname: any;

}


const AddDocument = (props: any) => {
    const [modal, setModal] = useState(false);
    const [upload, setupload] = useState<any>()

    let EmpId = localStorage.getItem("employee_id");

    const [UploadingDetails, setUploadingDetails] = useState(
        {
            EmpId: `${EmpId}`,
            doc_type: '',
            doc_info: '',
            file: '',
        }
    );


    const toggle = () => { setModal(!modal) };

    const handleOnChange = (event: any) => {

        setUploadingDetails({
            ...UploadingDetails,
            [event.target.name]: event.target.value
        });
    }
    const handleOnFileChange = (e: any) => {
        const fileList = e.target.files;
        setupload(fileList[0])

    };

    const uploadJoiningFile = async (document: any) => {
        let form_data = new FormData();
        form_data.append('employee_id', UploadingDetails.EmpId)
        form_data.append('doc_type', UploadingDetails.doc_type)
        form_data.append('doc_info', UploadingDetails.doc_info)
        if (upload && upload.name) {
            form_data.append('file', upload, upload.name)
        }


        try {
            const res = await axios.post(`${environment.baseUrl}User-document-upload`, form_data);

            return res.data
        }
        catch (err) {
            console.log('err');
            return err

        }
        return

    };
    const onSubmit = async (document: any) => {
        uploadJoiningFile(document).then((docResponse) => {
            if (docResponse.status === 'success') {
                swal({
                    title: "Success",
                    text: 'Document Uploaded Sucessfully',
                    icon: "success",
                })
                props.update(true)
                setModal(!modal)

            }
        });
    }


    return (
        <>
            <Button className='ml-3  base-bg-color text-white mt-1 border-0 py-2 base-font-600 shadow-none' onClick={toggle}>
            <i className="fa-solid fa-arrow-up-from-bracket fs-6 pe-1"></i>  Add Document
            </Button>
            <Modal isOpen={modal} toggle={toggle} size="xl" style={{ maxWidth: '30rem', width: '100%' }}>

                <ModalHeader toggle={toggle} className="d-flex align-items-center" >
                    <span className="base-text-color base-font-600">
                    Add Documents
                    </span > </ModalHeader>

                <ModalBody style={{
                    backgroundImage: 'linear-gradient(to top bottom, lightgray, red)', fontWeight: 400, color: "black", padding: 0, marginLeft: '25%'
                }} className="py-4">
                    <Form>
                        <div className="InputFeild" >
                            <Label htmlFor="employee_id" className="base-font-600" >
                                EmployeeID :
                            </Label>
                            <Label
                                htmlFor="employee_id" className="base-font-600"
                                
                                value={UploadingDetails.EmpId}
                            >
                                {UploadingDetails.EmpId}
                            </Label>

                            {/* {errors.first_name && <span className="text-danger">Please enter First Name</span>} */}
                        </div>

                        <div className="InputFeild">
                            <Label htmlFor="doc_type" className="base-font-600" style={{ marginTop: "20px", marginLeft: '-51px' }}>
                                Document Type :
                            </Label>
                            <select id="doc_type" name="doc_type" value={UploadingDetails.doc_type} onChange={handleOnChange} style={{ paddingLeft: '0.5rem', marginLeft: "0.5rem", fontSize: 16, width: '55%', height: '2.2rem', borderRadius: '5px' }}>
                                <option value="">Options..</option>
                                <option value="aadhar card">Aadhar Card</option>
                                <option value="pan card">Pan- Card</option>
                                <option value="other document">Other Document</option>
                            </select>
                        </div>

                        <div className="InputFeild" style={{ marginLeft: "-119px" }}>
                            <Label htmlFor="doc_info" className="base-font-600" style={{ marginTop: "20px", marginLeft: '20px' }}>
                                Document Information :
                            </Label>
                            <input
                                type="textarea"
                                id="doc_info"
                                name="doc_info"
                                placeholder="Marksheet,Electioncard,etc"
                                value={UploadingDetails.doc_info}
                                onChange={handleOnChange}
                                style={{ paddingLeft: '0.3rem', fontSize: 16, marginLeft: "0.5rem", width: '42%', height: '2.2rem', borderRadius: '5px' }}
                            />
                            {/* {errors.first_name && <span className="text-danger">Please enter First Name</span>} */}
                        </div>

                        <div className="InputFeild" style={{ marginLeft: "39px" }}>
                            <Label htmlFor="file" className="base-font-600" style={{ marginTop: "20px", marginLeft: '-50px' }}>
                                Document :
                            </Label>
                            <input
                                type="file"
                                id="file"
                                name="file"
                                onChange={(e) => { handleOnFileChange(e) }}
                                style={{ paddingLeft: '0.5rem', fontSize: 16, width: '80%', height: '2.2rem', borderRadius: '5px', marginBottom: "1rem " }}
                            />
                            {/* {errors.first_name && <span className="text-danger">Please enter First Name</span>} */}
                        </div>



                    </Form>
                </ModalBody>
                <ModalFooter
                    className="py-2"
                >
                    <Button
                        type="submit"
                        form="details"
                        value="Submit"
                        onClick={onSubmit}
                        className="base-bg-color text-white mt-1 border-0 py-2 base-font-600 shadow-none"
                    >Upload</Button>{' '}
                    <Button  onClick={toggle}
                        className="btn-secondary text-white mt-1 border-0 py-2 base-font-600 shadow-none"
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal >
        </>
    )
}

export default AddDocument


