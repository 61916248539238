import React, { useEffect, useState } from 'react'
import './attendance_css/sb-admin-2.css'
import 'font-awesome/css/font-awesome.min.css';
import Favicon from '../../image/Favicon.png'
import axios from 'axios';
import moment from 'moment';
import Comments from '../Comments';
import { environment } from '../../env';

// import './attendance_css/
interface UserData {
  firstname: string;
  lastname: string;
  profile_pic: any;
}

interface UserData2 {
  date: any;
  day: any;
  login_time: any;
  logout_time: any;
  comment: any;
  remark: any
}



const UserAttendance = () => {

  const [posts, setPost] = useState<UserData2[]>([]);
  const [latestPost, setLatestPost] = useState()
  const [update, setUpdate] = useState(false)
  const [userDetails, setUserDetails] = useState<UserData>({
    firstname: '',
    lastname: '',
    profile_pic: '',
  });

  let EmpId = localStorage.getItem("employee_id");

  const [UserTimeOut, setUserTimeOut] = useState()

  var fileDownload = require('js-file-download');
  const handlePDFDownload = () => {
    axios.get(`${environment.baseUrl}joiningletter-download/${EmpId}`, {
      responseType: 'blob',
    }).then(res => {
      fileDownload(res.data, `${userDetails.firstname}-JoiningLetter.pdf`);
    }).catch(err => {
      console.log(err);
    })
  }

  const EmployeeDetails = async () => {
    await axios.get(`${environment.baseUrl}user-details/${EmpId}`)
      .then(res => {
        setUserDetails(res.data);
      })
      .catch(res => console.log("error"))
  }

  const EmployeeAttendance = () => {
    axios.get(`${environment.baseUrl}get-employee-attendance/${EmpId}`)
      .then(res => {
        setPost(res.data);
        setLatestPost(res.data[0])
      })
      .catch(res => console.log("error"));
  };
  useEffect(() => {
    EmployeeDetails()
    EmployeeAttendance()
  }, [update]);

  return (
    <>

      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <div className="container-fluid ">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 base-text-color base-font-bold pt-2 ps-3" >Attendance</h1>
              <div className='d-sm-flex '>
              </div>
              {/* <Comments post={latestPost}/> */}
              {/* <button type="button" className="btn btn-danger d-none d-sm-inline-block btn btn-sm  shadow-sm" >Logout</button> */}
            </div>
            <table className="table table-hover">
              <thead style={{ color: "black", fontWeight: 500, fontSize: 18 }} key="thead">
                <tr>

                  <th scope="col">
                    Date
                  </th>
                  <th scope="col">Day</th>
                  <th scope="col">Time-In</th>
                  <th scope="col">Time-Out</th>
                  <th scope="col">Comments</th>
                  <th scope="col">Remark</th>
                </tr>
              </thead>
              <tbody style={{ fontWeight: 500, fontSize: 15 }} key="tbody">
                {posts && posts?.map((post: any) => {
                  return (
                    <tr key={post.id}>
                      <th scope="row" >{post.date}</th>
                      <td >{moment(post.day).format('dddd')}</td>
                      <td style={{ fontWeight: 500, fontSize: 15 }}>{post?.mispunch == "True" ?
                        moment(post.login_time.split("T")[1], "HH:mm:ss").format('hh:mm A') :
                        post?.login_time == "null" ? <span style={{ paddingLeft: '30px' }}>-</span> : moment(post.login_time.split(" ")[1].split(".")[0], "HH:mm:ss").format('hh:mm A')}</td>
                      <td style={{ fontWeight: 500, fontSize: 15 }} >{post?.mispunch == "True" ? post?.logout_time === "null" ? <span style={{ paddingLeft: '30px' }}>-</span> :
                        moment(post.logout_time.split("T")[1], "HH:mm:ss").format('hh:mm A') : post?.logout_time === "null" ? <span style={{ paddingLeft: '30px' }}>-</span> :
                        moment(post.logout_time.split(" ")[1].split(".")[0], "HH:mm:ss").format('hh:mm A')}</td>
                      <td ><div className="comments-scroll">{post.comment == "null" && moment(new Date()).format('YYYY-MM-DD') ? <Comments post={post.date} update={setUpdate} /> : post.comment}</div></td>
                      <td >{post.remark}</td>


                      {/* <td><Button color="danger">Comment</Button></td> */}
                    </tr>

                  )

                })}

              </tbody>

            </table>

          </div>
          {/* /.container-fluid */}
        </div>
        {/* End of Main Content */}
        {/* Footer */}
      </div>



    </>

  )
}

export default UserAttendance
