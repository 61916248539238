import { LoginActionTypes } from "../actions/LoginActions";

const initialState = {
    userData: undefined
}

const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LoginActionTypes.SET_USER_DATA:
            return {
                userData: action.user
            }
        default:
            return state
    }

}

export default LoginReducer