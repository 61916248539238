import { LOGIN } from "../actions/types";

const initialState = {
  loggedIn: false
};
const reducers = (state = initialState, action:any) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loggedIn: action.data
      }
    default:

      return state;
  }

}

export default reducers






