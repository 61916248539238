import React, { useEffect, useState } from 'react';
import swal from "sweetalert";
import { Card, CardBody, CardFooter, CardTitle, Input, Label } from 'reactstrap';
import axios from 'axios';
import { Button } from 'reactstrap';
import { environment } from '../../../env';
import defaultimage from '../../../image/DefaultProfileLogo.png'
import '../Basic_Details/BasicDetails.css'
import { useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css'
import { BsFillCameraFill } from 'react-icons/bs';
import PhoneInput from 'react-phone-number-input'

function BasicDetails(props: any) {

    const [show, setShow] = useState(true)
    const [userDetail, setUserDetail] = useState(
        {
            firstname: props.basicdetails.firstname,
            lastname: props.basicdetails.lastname,
            gender: props.basicdetails.gender,
            email_id: props.basicdetails.email_id,
            phone_no: props.basicdetails.phone_no,
            profile_pic: props.basicdetails.profile_pic,
            office_email: props.basicdetails.office_email,
            personal_email: props.basicdetails.personal_email,
            pan: props.basicdetails.pan,
            aadhar: props.basicdetails.aadhar,
            emg_contact_name: props.basicdetails.emg_contact_name,
            relationship_with_emg_contact: props.basicdetails.relationship_with_emg_contact,
            emg_contact_number: props.basicdetails.emg_contact_number,
            reporting_manager: props.basicdetails.reporting_manager,
            check_in_time: props.basicdetails.check_in_time,
            check_out_time: props.basicdetails.check_out_time,
            blood_group: props.basicdetails.blood_group,
            date_of_birth: props.basicdetails.date_of_birth,
            department: props.basicdetails.department,
            designation: props.basicdetails.designation,
            date_of_joining: props.basicdetails.date_of_joining,
            probation_period: props.basicdetails.probation_period,
            notice_period: props.basicdetails.notice_period
        }
    );
    const [upload, setUpload] = useState<any>()
    const [contect, setContect] = useState<any>()
    const [emgcontectno, setEmgContectNo] = useState<any>()
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();

    const imageUploader = React.useRef(null);

    const handleOnChange = (event: any) => {
        setUserDetail({
            ...userDetail,
            [event.target.name]: event.target.value
        });
    }
    const handleImageChange = (e: any) => {
        const fileList = e.target.files;
        setUpload(fileList[0]);
    };

    useEffect(() => {

    }, [])

    const ProfilePostMethod = async () => {
        let form_data = new FormData();
        form_data.append('personal_email', userDetail.personal_email)
        form_data.append('pan', userDetail.pan)
        form_data.append('aadhar', userDetail.aadhar)
        form_data.append('blood_group', userDetail.blood_group)
        form_data.append('gender', userDetail.gender)
        form_data.append('date_of_birth', userDetail.date_of_birth)
        form_data.append('emg_contact_name', userDetail.emg_contact_name)
        form_data.append('relationship_with_emg_contact', userDetail.relationship_with_emg_contact)
        form_data.append('emg_contact_number', emgcontectno)
        form_data.append('phone_no', contect)
        if (upload && upload.name) {
            form_data.append('profile_pic', upload, upload.name);
        }
        try {
            const res = await axios.put(`${environment.baseUrl}update-basic-details/${props.basicdetails.employee_id}`, form_data);
            return res.data
        }
        catch (err) {
            console.log("err");
            return err;
        }
        return
    };

    const onSubmit = async (e: any) => {
        e.preventDefault();
        ProfilePostMethod().then((profileResponse) => {
            if (profileResponse.status === 'success') {
                swal({
                    title: "Success",
                    text: 'Profile is updated successfully',
                    icon: "success",
                })
                    .then(() => {
                        props.update(true)
                        setShow(!show)
                    })
            }
        });

    }

    return (
        <>
            <Card className='mt-3 mx-3 mb-5'>
            <CardTitle className='BDcardtitle BD-tables-head d-flex justify-content-between align-items-center px-3 py-3'>
                    <h4 className="mb-0 base-font-700 base-text-color">Basic Details</h4>
                    <div className=''>
                              {show &&  <Button className='base-bg-color border-0 px-4 outline-none base-font-600 fw-bold base-font-600 fw-bold'  onClick={() => setShow(!show)}

                                >
                                    <i className="fa-regular fa-pen-to-square" style={{ marginRight: "5px" }}></i>  EDIT
                                </Button>}
                            </div>
                </CardTitle>
                {show ?
                    <>
                        <CardBody>
                            <div className="row ">
                                <div className="col-lg-3">
                                    <div className="row g-3">
                                        <div className=" col-12 d-flex justify-content-center align-content-center pb-3">
                                            <div className="BD-profile-img d-flex justify-content-center">
                                                <img className='BD-profile-img img'
                                                    src={props.basicdetails.profile_pic ? `${environment.imageUrl}${props.basicdetails.profile_pic}` : `${defaultimage}`}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-lg-0 d-lg-block d-sm-flex mt-md-0 mb-md-3 p-md-0">
                                            <div className=" col-lg-12  col-md-4  mb-lg-4">
                                                <div className="d-flex flex-column">
                                                    <label className='BD-labels mb-2 base-font-600 fs-6 base-font-600' >Name</label>
                                                    <label className='fs-6 label-inputs' >{userDetail.firstname} {userDetail.lastname}</label>
                                                </div>
                                            </div>
                                            <div className=" col-lg-12 col-md-4  mb-lg-4">
                                                <div className="d-flex flex-column">
                                                    <label className='BD-labels mb-2 base-font-600 fs-6 base-font-600' >Email</label>
                                                    <label className='fs-6 label-inputs ' >{userDetail.personal_email}</label>
                                                </div>
                                            </div>
                                            <div className=" col-lg-12  col-md-4 ">
                                                <div className="d-flex flex-column">
                                                    <label className='BD-labels mb-2 base-font-600 fs-6 base-font-600' >Contact Number</label>
                                                    <label className='fs-6 label-inputs fs-5' >{userDetail.phone_no}</label>
                                                </div>
                                            </div>
                                            {/* <div className="Designation col-12">
                                        <div className="d-flex flex-column">
                                            <label className='BD-labels mb-2 base-font-600 fs-6 base-font-600' >Designation</label>
                                            <label className='fs-6 label-inputs fs-5' >null</label>
                                        </div>
                                    </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-9">
                                    <div className="row g-3">
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' >First Name</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.firstname}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' >Last Name </label>
                                                <label className='fs-6 label-inputs ' >{userDetail.lastname}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' >Personal Email</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.email_id}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600 pb-1' >Contact Number</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.phone_no}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600 pb-1' >Pan Number</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.pan}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600 pb-1' >Aadhar Number</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.aadhar}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600 pb-1' >Blood Group</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.blood_group}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' >Gender</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.gender}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' >Date Of Birth</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.date_of_birth}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' >Emergency Contact Name</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.emg_contact_name}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' >Relation with Emergency Contact</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.relationship_with_emg_contact}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' >Emergency Contact Number</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.emg_contact_number}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' >Office Email</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.email_id}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' >Date of Joining</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.date_of_joining}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' > Reporting Manager</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.reporting_manager}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' > Check-In Time</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.check_in_time}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' >Check-Out Time</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.check_out_time}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' > Department</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.department}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' >Designation</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.designation}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' > Probation Period</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.probation_period}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="d-flex flex-column">
                                                <label className='BD-labels mb-2 base-font-600' >Notice Period</label>
                                                <label className='fs-6 label-inputs ' >{userDetail.notice_period}</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* <div className="row text-end mt-4">
                            <Link to={'editbasicdetails'}>
                                <Button color='primary'
                                    style={{ width: "150px", marginTop: "10px" }}
                                ><i className="fa-regular fa-pen-to-square" style={{ marginRight: "5px" }}></i>Edit</Button>{' '}
                            </Link>
                        </div> */}

                            </div>
                        </CardBody>
                    </> :
                    <>
                        <form id="details" onSubmit={(e) => onSubmit(e)}>
                            <CardBody>
                                    <div className="row px-2 py-0 my-0">
                                        <div className=" col-lg-9 order-last">
                                            <div className="row " id="main">
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600" >First Name</p>
                                                        <label className="border-1 w-100 Labels-font" style={{ paddingTop: "2px", fontSize: 17 }}>{userDetail.firstname}</label>
                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600" >Last Name</p>
                                                        <label className="border-1 w-100 Labels-font" style={{ paddingTop: "2px", fontSize: 17 }}>{userDetail.lastname}</label>
                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600 pb-2" >Personal Email</p>
                                                        <Input
                                                            type="text"
                                                            id="pemail"
                                                            name="personal_email"
                                                            value={userDetail.personal_email}
                                                            onChange={handleOnChange}
                                                            placeholder="Personal Email"
                                                            className="py-1 fs-6 shadow-none input-border"
                                                        // style={{ paddingLeft: '1rem', fontSize: 14, width: '100%', height: '1.7rem' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600 pb-2" >Contact Number</p>
                                                        <PhoneInput
                                                            type="tel"
                                                            maxLength={12}
                                                            defaultCountry="IN"
                                                            value={contect}
                                                            onChange={setContect}
                                                            placeholder="Contact Number"
                                                            className="py-1 pt-2 mt-1 fs-6 shadow-none input-border"
                                                        />
                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600 pb-2" >Pan Number</p>
                                                        <Input
                                                            className="py-1 fs-6 shadow-none input-border"
                                                            type="text"
                                                            id="pancard_number"
                                                            name="pan"
                                                            maxLength={10}
                                                            value={userDetail.pan}
                                                            onChange={handleOnChange}
                                                            placeholder="Pan Card Number"

                                                        />
                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600 pb-2" style={{ fontSize: 14, fontWeight: 600, }}>Aadhar Number</p>
                                                        <Input
                                                            type="text"
                                                            id="Aadhar_number"
                                                            name="aadhar"
                                                            maxLength={12}
                                                            value={userDetail.aadhar}
                                                            onChange={handleOnChange}
                                                            placeholder="Aadhar Card Number"
                                                            className="py-1 fs-6 shadow-none input-border"

                                                        />


                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600 pb-2 " >Blood Group</p>
                                                        <Input
                                                            type="text"
                                                            id="blood_group"
                                                            name="blood_group"
                                                            className="py-1 fs-6 shadow-none input-border"
                                                            value={userDetail.blood_group}
                                                            onChange={handleOnChange}
                                                            placeholder="Blood Group"

                                                        />
                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600 pb-2" >Gender</p>
                                                        <div className="d-flex">
                                                            <div className="d-flex pt-2">
                                                                <input
                                                                    type="radio"
                                                                    id="male"
                                                                    name='gender'
                                                                    value='Male'
                                                                    checked={userDetail.gender === "Male"}
                                                                    onChange={handleOnChange}
                                                                    placeholder="Example@gmail.com"

                                                                />
                                                                {"  "}
                                                                <div className="ps-1 fs-6 Labels-font label-inputs base-font-600">
                                                                    Male
                                                                </div>
                                                            </div>
                                                            <div className="d-flex pt-2">
                                                                <input
                                                                    type="radio"
                                                                    id="female"
                                                                    name='gender'
                                                                    value="Female"
                                                                    checked={userDetail.gender === "Female"}
                                                                    onChange={handleOnChange}
                                                                    style={{
                                                                        marginLeft: '30px',
                                                                        fontWeight: 300
                                                                    }}
                                                                />
                                                                {"  "}
                                                                <div className="ps-1 fs-6 Labels-font label-inputs base-font-600">Female</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600 pb-2" >Date Of Birth</p>
                                                        {/* <label className="border-1 w-100" style={{ paddingTop: "2px", fontSize: 15 }}>{userDetail.date_of_birth}</label> */}
                                                        <Input
                                                            type="date"
                                                            id="dob"
                                                            name="date_of_birth"
                                                            value={userDetail.date_of_birth}
                                                            onChange={handleOnChange}
                                                            placeholder="Date Of Birth"
                                                            className="py-1 fs-6 shadow-none input-border"
                                                        />
                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600 pb-2" >Emergency Contact Name</p>
                                                        <Input
                                                            type="text"
                                                            id="emg_contact_name"
                                                            name="emg_contact_name"
                                                            value={userDetail.emg_contact_name}
                                                            onChange={handleOnChange}
                                                            placeholder="Emergency Contact Person"
                                                            className="py-1 fs-6 shadow-none input-border"

                                                        />
                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600 pb-2" >Relation Emergency Contact</p>
                                                        <Input
                                                            type="text"
                                                            id="relationship_with_emg_contact"
                                                            name="relationship_with_emg_contact"
                                                            value={userDetail.relationship_with_emg_contact}
                                                            onChange={handleOnChange}
                                                            placeholder="Relation with Emergency Contact"
                                                            className="py-1 fs-6 shadow-none input-border"
                                                        />
                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600 pb-2" >Emergency Contact Number</p>

                                                        <PhoneInput
                                                           className="py-2 pt-2 mt-2 fs-6 shadow-none input-border"
                                                            maxLength={12}
                                                            defaultCountry="IN"
                                                            value={emgcontectno}
                                                            onChange={setEmgContectNo}
                                                            placeholder="Emergency Contact Number"
                                                            style={{ fontSize: 14, width: '100%', height: '1.7rem' }}
                                                        />

                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600" >Office Email</p>
                                                        <label className="border-1 w-100 Labels-font" style={{ paddingTop: "2px", fontSize: 15 }}>{userDetail.email_id}</label>

                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600" >Date of Joining</p>
                                                        <label className="border-1 w-100 Labels-font" style={{ paddingTop: "2px", fontSize: 15 }}>{userDetail.date_of_joining}</label>
                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600" >Reporting Manager</p>
                                                        <label className="border-1 w-100 Labels-font" style={{ paddingTop: "2px", fontSize: 15 }}>{userDetail.reporting_manager}</label>

                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600" >Check-In Time</p>
                                                        <label className="border-1 w-100 Labels-font" style={{ paddingTop: "2px", fontSize: 15 }}>{userDetail.check_in_time}</label>

                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600" >Check-Out Time</p>
                                                        <label className="border-1 w-100 Labels-font" style={{ paddingTop: "2px", fontSize: 15 }}>{userDetail.check_out_time}</label>

                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600" >Department</p>
                                                        <label className="border-1 w-100 Labels-font" style={{ paddingTop: "2px", fontSize: 15 }}>{userDetail.department}</label>

                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600" >Designation</p>
                                                        <label className="border-1 w-100 Labels-font" style={{ paddingTop: "2px", fontSize: 15 }}>{userDetail.designation}</label>

                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600" >Probation Period</p>
                                                        <label className="border-1 w-100 Labels-font" style={{ paddingTop: "2px", fontSize: 15 }}>{userDetail.probation_period}</label>
                                                    </div>
                                                </div>
                                                <div className=" col-4">
                                                    <div className="p-2">
                                                        <p className="m-0 BD-labels-head base-font-600" >Notice Period</p>
                                                        <label className="border-1 w-100 Labels-font" style={{ paddingTop: "2px", fontSize: 15 }}>{userDetail.notice_period}</label>
                                                    </div>
                                                    {/* <div className="BD-submit">
                                                    <Button
                                                        color='success'
                                                        type="submit"
                                                        form="details"
                                                        value="Submit"

                                                    >Submit</Button>
                                                </div> */}

                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-lg-3 ">
                                            <div className="row g-3">
                                                <div className="col-12 ">
                                                    <div className="d-flex justify-content-center  position-relative" style={{}}>
                                                        <img
                                                            src={userDetail.profile_pic ? `${environment.imageUrl}${userDetail.profile_pic}` : `${defaultimage}`}
                                                            className="BD-profile-img img "
                                                        />
                                                         <div className='upload-img-box shadow-lg p-1  rounded-circle'>
                                                            <label htmlFor="file-input" className='p-2'>
                                                            <BsFillCameraFill
                                                        />
                                                            </label>
                                                        <input
                                                            id='file-input'
                                                            type="file"
                                                            className=''
                                                            name='profile_pic'
                                                            ref={imageUploader}
                                                            accept="image/jpeg,image/png,image/gif"
                                                            onChange={(e) => { handleImageChange(e) }}
                                                            style={
                                                                {
                                                                    color: 'transparent',
                                                                    zIndex:-10, background: 'transparent',
                                                                    
                                                                }} />
                                                                
                                                    </div>

                                                    </div>

                                                   
                                                    
                                                </div>
                                                <div className="row col-12 d-lg-block d-sm-flex mt-md-0  ms-0">
                                                    <div className=" p-0 BD-ED-display">
                                                        <div className="p-2 BD-ED-width">
                                                            <p className="m-0 BD-labels-head base-font-600 fs-6" >Name</p>
                                                            <label className="border-1 w-100 Labels-font" style={{ paddingTop: "2px", fontSize: 15 }}>{userDetail.firstname} {userDetail.lastname}</label>
                                                        </div>
                                                        <div className="p-2 BD-ED-width">
                                                            <p className="m-0 BD-labels-head base-font-600 fs-6" >Email</p>
                                                            <label className="border-1 w-100 Labels-font" style={{ paddingTop: "2px", fontSize: 15 }}>{userDetail.personal_email}</label>
                                                        </div>
                                                        <div className="p-2 BD-ED-width">
                                                            <p className="m-0 BD-labels-head base-font-600 fs-6" >Contact Number</p>
                                                            <label className="border-1 w-100 Labels-font" style={{ paddingTop: "2px", fontSize: 15 }}>{userDetail.phone_no}</label>
                                                        </div>
                                                        <div className="p-2 BD-ED-none">
                                                            <p className="m-0 BD-labels-head base-font-600 fs-6 " >Designation</p>
                                                            <label className="border-1 w-100" style={{ paddingTop: "2px", fontSize: 15 }}>{userDetail.designation}</label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </CardBody>
                            <CardFooter>
                                    <div className='d-flex justify-content-end gap-3'>
                                <Button className='bg-info border-0 px-4 outline-none base-font-600 fw-bold py-2 fs-6'  

                                >
                                   <i className="fa-regular fa-pen-to-square pe-1"></i>  Update
                                </Button>
                                <Button className='bg-danger border-0 px-4 outline-none base-font-600 fw-bold py-2 fs-6'
                                onClick={() => setShow(!show)}

                                >
                                   <i className="fa-regular fa-trash-can ps-1"></i>  Cancel
                                </Button>
                            </div>
                            </CardFooter>
                        </form>
                    </>
                }

            </Card>

        </>
    )
}

export default BasicDetails