import React, { useState } from 'react';
import swal from "sweetalert";
import axios from 'axios';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Form,
} from 'reactstrap';
import { environment } from '../env';

const RewardsModal = (props: any) => {
    const [modal, setModal] = useState(false);
    const EmpId = localStorage.getItem('employee_id')
    const [upload, setupload] = useState<any>()
    const [rewards, setRewards] = useState(
        {
            EmpId: `${EmpId}`,
            doc_type: 'Rewards',
            count: 0,
            doc_info: ''
        }
    );

    const toggle = () => { setModal(!modal) };

    const handlefile = (e: any) => {
        let f = e.target.files[0];
        if (f) {
            const fileList = e.target.files;
            setupload(fileList[0])
            let r = new FileReader();

            r.onload = function (e) {
                let contents = `${e.target?.result}`;
                let res = contents.split(' ');
                setRewards({
                    ...rewards,
                    count: res.length
                });
            }
            r.readAsText(f);
        }
    }


    const uploadJoiningFile = async () => {
        let form_data = new FormData();
        form_data.append('employee_id', rewards.EmpId)
        form_data.append('doc_type', rewards.doc_type)
        form_data.append('doc_info', `${rewards.count}`)
        if (upload && upload.name) {
            form_data.append('file', upload, upload.name)
        }
        try {
            const res = await axios.post(`${environment.baseUrl}User-document-upload`, form_data);
            return res.data
        }
        catch (err) {
            console.log('err');
            return err
        }
        return
    };
    const onSubmit = async (e: any) => {
        uploadJoiningFile().then((docResponse) => {
            if (docResponse.status === 'success') {
                swal({
                    title: "Success",
                    text: 'Document Uploaded Sucessfully',
                    icon: "success",
                }).then(
                    props.update(true)
                ).then(toggle)
            }
        });
    }

    const submit = (e: any) => {
        e.preventDefault();
        axios.post(`${environment.baseUrl}rewards/${EmpId}`, {
            count: rewards.count
        })
    }

    const two = (e: any) => {
        submit(e);
        onSubmit(e);
    }

    return (
        <>
                <Button className='ml-3  base-bg-color text-white mt-1 border-0 py-2 base-font-600 shadow-none' onClick={toggle}>
                <i className="fa-solid fa-gift fs-6 pe-1"></i>  Rewards
            </Button>
            <Modal isOpen={modal} toggle={toggle} size="xl" style={{ maxWidth: '30rem', width: '100%' }}>

                <ModalHeader toggle={toggle} style={{ background: "white", color: "#003C83", fontWeight: 900 }}>REWARDS</ModalHeader>

                <ModalBody style={{
                    backgroundImage: 'linear-gradient(to top bottom, lightgray, red)', fontWeight: 400, color: "black", padding: 0, marginLeft: '25%'
                }}>
                    <Form style={{ marginTop: "40px", marginBottom: "20px" }}>

                        <input
                            name="file"
                            type="file"
                            id="file"
                            onChange={handlefile}
                            accept="text/plain"
                            accessKey="u" />
                        <br />
                        <div style={{ marginTop: "15px" }}>Your Document has <span id="display_File_count">{rewards.count}</span><span> words</span></div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="success"
                        onClick={two}>
                        Submit
                    </Button>{' '}
                    <Button color="danger" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal >
        </>
    )
}

export default RewardsModal