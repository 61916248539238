import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import axios from 'axios';
import { Card } from '@mui/material';
import { truncate } from 'fs/promises';
import swal from 'sweetalert';
import { environment } from '../../env';

interface UserData {
    firstname: any;
    lastname: any;
}

function Mispunch(props: any) {
    const url = "";
    let userId = props.post;
    let EmpId = localStorage.getItem('employee_id')
    let user = localStorage.getItem('userid')
    const [modal, setModal] = useState(false);
    const [post, setPost] = useState<UserData>({
        firstname: '',
        lastname: ''
    });
    const [backdrop, setBackdrop] = useState(true);
    const [data, setData] = useState({
        date: '',
        timein: '',
        timeout: '',
        comment: ''
    })

    const [keyboard, setKeyboard] = useState(true);

    const toggle = () => setModal(!modal);

    const handle = (e: any) => {
        const newdata: any = { ...data };
        newdata[e.target.id] = e.target.value;
        setData(newdata);
    }

    const submit = (e: any) => {
        props.update
        e.preventDefault();
        // axios.post('url', {
        axios.post(`${environment.baseUrl}mispunch/`, {
            employee_id: EmpId,
            date: data.date,
            day: data.date,
            login_time: moment(data.timein, 'HH:mm').format(),
            logout_time: moment(data.timeout, 'HH:mm').format(),
            mispunch: "True",
            remark: "pending",
            comment: data.comment,
            user: user
        }).then(
            res => {
                if (res.status == 200) {
                    swal({
                        title: "Success",
                        text: 'Mispunch Applied Successfully',
                        icon: "success",
                    })
                    props.update(true);
                }

            }
        ).catch(res => console.log("error"))
    }

    const EmployeeDetails = () => {
        axios.get(`${environment.baseUrl}user-details/${EmpId}`)
            .then(res => {
                setPost(res.data);
            })
            .catch(res => console.log("error"))
    }

    useEffect(() => {
        EmployeeDetails()
    }, []);
    return (
        <div>
            <Form inline onSubmit={(e) => e.preventDefault()}>
                <Button className='ml-3  base-bg-color text-white mt-1 border-0 py-2 base-font-600 shadow-none' onClick={toggle}>
            <i className="fa-regular fa-file-lines fs-6 pe-1"></i>  Mispunch
            </Button>
            </Form>
            <Modal
                isOpen={modal}
                toggle={toggle}
                backdrop={backdrop}
                keyboard={keyboard}
                style={{}}
            >
                <ModalHeader toggle={toggle} className="base-font-bold base-text-color fs-6">Mispunch</ModalHeader>
                <ModalBody style={{ backgroundImage: 'linear-gradient(to bottom right, white, white)', fontWeight: 400 }}>
                    <Form id='details' onSubmit={(e) => { submit(e) }}>
                        <Card className="shadow-none fs-5 base-font-600 ">
                            Name : {post.firstname} {post.lastname}
                        </Card>
                        <div style={{ justifyContent: "space-between", flexDirection: "row", marginTop: "30px", display: "flex", gap: 20 }}>

                            <div style={{ width: "50%" }}>
                                <Label for='date' className="text-black base-font-600">
                                    Date
                                </Label>
                                <br />
                                <Input
                                    className='py-1 fs-6 shadow-none input-border'
                                    id="date"
                                    name="date"
                                    type="date"
                                    value={data.date}
                                    onChange={(e) => handle(e)}
                                /><br />
                                <Label for='timein' className="text-black base-font-600">
                                    TimeIn
                                </Label><br />
                                <Input
                                    className='py-1 fs-6 shadow-none input-border'
                                    id="timein"
                                    name="timein"
                                    type="time"
                                    value={data.timein}
                                    onChange={(e) => handle(e)}
                                /><br />

                            </div>

                            <div style={{ width: "50%", paddingTop: '8px' }}>
                                <Label className="text-black base-font-600">
                                    Day
                                </Label>
                                <br />
                                <Label value="default" claaName="py-1 fs-6 shadow-none input-border text-black base-font-600" >
                                    {moment(data.date).format('dddd')}
                                </Label>
                                <br />
                                <br />
                                <Label for='timeout' className="text-black base-font-600">
                                    TimeOut
                                </Label><br />
                                <Input
                                    className='py-1 fs-6 shadow-none input-border'
                                    id="timeout"
                                    name="timeout"
                                    type="time"
                                    value={data.timeout}
                                    onChange={(e) => handle(e)}
                                /><br />
                            </div>
                        </div>
                        <br />
                        <div>
                            <Label for='comment' className="text-black base-font-600">
                                Comment
                            </Label>

                            <br />
                            <Input
                                className='py-1 px-1 fs-6 shadow-none input-border-rounded '
                                id="comment"
                                name="comment"
                                placeholder="Comment"
                                type="textarea"
                                value={data.comment}
                                row={5}
                                onChange={(e) => handle(e)}
                                style={{ fontSize: 14 }}
                            /><br />
                        </div>

                    </Form>


                </ModalBody>
                <ModalFooter
                    className="py-2"
                >
                    <Button
                        type="submit"
                        form="details"
                        value="Submit"
                        onClick={toggle}
                        className="base-bg-color text-white mt-1 border-0 py-2 base-font-600 shadow-none"
                    >Submit</Button>{' '}
                    <Button  onClick={toggle}
                        className="btn-secondary text-white mt-1 border-0 py-2 base-font-600 shadow-none"
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default Mispunch;