export const LoginActionTypes = {
    SET_USER_DATA: "SET_USER_DATA",

}

export function setUserData(user) {
    return {
        type: LoginActionTypes.SET_USER_DATA,
        user
    }

}