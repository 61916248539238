import React, { useEffect, useState } from 'react';
import {
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  NavLink, TabContent, TabPane, Row, Col, Card, CardTitle, CardText, Button
} from 'reactstrap';
import UserDocuments from '../udocuments';
import Address from './Address';
import BankDetails from './BankDetails';
import BasicDetails from './BasicDetails';
import joiningLink from '../../../image/joining-link.svg';
import EditBasicDetails from './EditBasicDetails';
import Education from './Education';
import Experience from './Experience';
import FamilyDetails from './FamilyDetails';
import axios from 'axios';
import { environment } from '../../../env';
import './BasicDetails.css'

interface UserData {
  firstname: string;
  lastname: string;
  profile_pic: any;
}

function DetailsNavigation() {
  const [deti, setDeti] = useState(false)
  const [activatedTab, setActivatedTab] = useState("1");
  const [update, setUpdate] = useState(false)
  const [userDetails, setUserDetails] = useState({
    firstname: '',
    lastname: '',
    profile_pic: '',
    bank_name: ' ',
    acc_holder_name: ' ',
    acc_number: ' ',
    acc_ifsc_number: ' ',
    acc_type: ' ',
    acc_branch: ' '
  });
  let EmpId = localStorage.getItem("employee_id");

  const activateTab = (tabId: any) => {
    setActivatedTab(tabId)
  }
  var fileDownload = require('js-file-download');

  const handlePDFDownload = () => {
    axios.get(`${environment.baseUrl}joiningletter-download/${EmpId}`, {
      responseType: 'blob',
    }).then(res => {
      fileDownload(res.data, `${userDetails.firstname}-JoiningLetter.pdf`);
      console.log(res);
    }).catch(err => {
      console.log(err);
    })
  }

  const EmployeeDetails = async () => {
    await axios.get(`${environment.baseUrl}user-details/${EmpId}`)
      .then(res => {
        setUserDetails(res.data);
        setDeti(true)
      })
      .catch(res => console.log("error"))
  }
  useEffect(() => {
    EmployeeDetails()
  }, [update]);

  return (
    <>
      {deti ?
        <>
          <div className='container-fluid' >
            <div className="d-flex Bd-menus">

              <div className='Cursors FS-14 menu-button-padding w-100 d-flex justify-content-between align-items-center py-3 px-4' >
                <Nav tabs
                  pills className='border-0 gap-2 base-font-600 '>

                  <NavItem>
                    <NavLink
                      className={activatedTab === "1" ? `active  fs-14 FS-14 menu-button-padding` : ''}
                      onClick={() => activateTab("1")}
                    >
                      Basic Details
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={activatedTab === "2" ? `active border-bottom-0 fs-14 menu-button-padding` : 'fs-14'}
                      onClick={() => activateTab("2")}
                    >
                      Documents
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={activatedTab === "3" ? `active border-bottom-0 fs-14 menu-button-padding` : 'fs-14'}
                      onClick={() => activateTab("3")}
                    >
                      Address
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activatedTab === "4" ? `active border-bottom-0 fs-14 menu-button-padding` : 'fs-14'}
                      onClick={() => activateTab("4")}
                    >
                      Education
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activatedTab === "5" ? `active border-bottom-0 fs-14 menu-button-padding` : 'fs-14'}
                      onClick={() => activateTab("5")}
                    >
                      Experience
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activatedTab === "6" ? `active border-bottom-0 fs-14 menu-button-padding` : 'fs-14'}
                      onClick={() => activateTab("6")}
                    >
                      Bank Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activatedTab === "7" ? `active border-bottom-0 fs-14 menu-button-padding` : 'fs-14'}
                      onClick={() => activateTab("7")}
                    >
                      Family Details
                    </NavLink>
                  </NavItem>
                </Nav>
                <div className="">
                <Button className='btn btn-warning border-0 text-nowrap base-font-700 fs-6 fw-semibold py-2 shadow-none text-white' onClick={() => handlePDFDownload()}>Joining Letter
                <img src={joiningLink} alt="" className="w-h-20 ps-1" />
                </Button>
                </div>
              </div>
            </div>
            <TabContent activeTab={activatedTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <h4>
                      <BasicDetails basicdetails={userDetails} update={setUpdate} />
                    </h4>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <h4>
                      <UserDocuments />
                    </h4>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    <h4>
                      <Address details={userDetails} update={setUpdate} />
                    </h4>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <Row>
                  <Col sm="12">
                    <h4>
                      <Education />
                    </h4>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="5">
                <Row>
                  <Col sm="12">
                    <h4>
                      <Experience />
                    </h4>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="6">
                <Row>
                  <Col sm="12">
                    <h4>
                      <BankDetails details={userDetails} update={setUpdate} />
                    </h4>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="7">
                <Row>
                  <Col sm="12">
                    <h4>
                      <FamilyDetails details={userDetails} />
                    </h4>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </> :
        <>

        </>}


    </>
  )

}

export default DetailsNavigation;


