import reducers from "./reducers";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  reducers,

});

export default rootReducer;
