import React, { useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment';
import { environment } from '../../../env';


interface UserData {
    date: any;
    day: any;
    name: any;
}

const Holidays = () => {
    const [posts, setPost] = useState<UserData[]>([]);

    const EmployeeAttendance = () => {
        axios.get(`${environment.baseUrl}holiday/`)
            .then(res => {
                setPost(res.data);
            })
            .catch(res => console.log("error"));
    };
    useEffect(() => {
        EmployeeAttendance()
    }, []);

    return (
        < >
        <div className="container-fluid">
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 pt-2 base-font-bold base-text-color" >HOLIDAYS</h1>

                </div>
                <div style={{
                    display: "flex",
                    justifyContent:"space-around"
                }}>
                    <table style={{ fontSize: 19, border: "2px solid black ", width: "60%", height: "80%" }} className="table table-hover">
                        <thead key="thead">
                            <tr style={{ color: "black", fontWeight: 500, fontSize: 18, textAlign: "center" }} >
                                <th style={{ border: "2px solid black " }} scope="col">ID</th>
                                <th style={{ border: "2px solid black " }} scope="col">Date</th>
                                <th style={{ border: "2px solid black " }} scope="col">Day</th>
                                <th style={{ border: "2px solid black " }} scope="col">Name</th>

                            </tr>
                        </thead>
                        <tbody key="tbody">
                            {posts && posts?.map((post: any) => {
                                return (
                                    <tr style={{ fontSize: 15, color: "black", textAlign: "center" }}>
                                        <td style={{ border: "2px solid black ", color: "black", fontWeight: 700 }}>{post.id}</td>
                                        {/* <td >{post.date}</td> */}
                                        <td style={{ border: "2px solid black ", color: "black", fontWeight: 700 }} >{moment(post.date).format('DD-MM-YYYY')}</td>
                                        <td style={{ border: "2px solid black ", color: "black", fontWeight: 700 }} >{moment(post.day).format('dddd')}</td>
                                        <td style={{ border: "2px solid black ", color: "black", fontWeight: 700 }} >{post.name}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div style={{width:"30%",color:"black",fontWeight:400}} className="base-font-700">
                    <span><h2><u><b>Leave Instructions</b></u></h2></span>
                    <ul>
                    <li><h6>Everyone should apply leaves before one month </h6><br/></li>
                    <li><h6>Every month you are eligible to take 1 leave,Total 12 Privilage leaves every year.</h6><br/></li>
                    <li><h6>After 12 Leaves, number of Leaves taken will get deducted from your salary (Un Paid Leaves)</h6><br/></li>
                    <li><h6>No leaves in <i>Probation period</i> (First 3 months after Joining Date)</h6><br/></li>
                    <li><h6>5<b><i> Sick leaves</i></b></h6><br/></li>
                    <li><h6>12<b><i> Privilaged leaves</i></b></h6></li>
                    </ul>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default Holidays