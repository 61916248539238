import React, { useEffect, useState } from 'react'
import ApplyLeaveModel from './ApplyLeaveModel'
import axios from 'axios';
import { environment } from '../../../env';

interface leaveData {
    employee_id: any;
    leave_type: any;
    apply_date: any;
    start_date: any;
    end_date: any;
    leave_durations: any;
    leave_reason: any;
    leave_status: any;
}
const LeavePage = () => {
    const [myleave, setMyLeave] = useState<leaveData[]>([])
    let EmpId = localStorage.getItem('employee_id')
    const Leaves = () => {
        axios.get(`${environment.baseUrl}get-my-leaves/${EmpId}`)
            .then(res => {
                setMyLeave(res.data);
            })
            .catch(res => console.log("error"))
    };
    useEffect(() => {
        Leaves()
    }, [])
    return (
        < >
        <div className="container-fluid">
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 base-text-color base-font-bold pt-2 ps-3"> My Leaves </h1>
                    <ApplyLeaveModel Leaves={() => Leaves()} />
                </div>
                <table className="table table-hover">
                    <thead style={{ color: "black", fontWeight: 500, fontSize: 18 }}key="thead">
                        <tr>
                            <th scope="col">EmpId</th>
                            <th scope="col">Leave Type</th>
                            <th scope="col">Apply Date</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                            <th scope="col">Leave Durations</th>
                            <th scope="col">Leave Reason</th>
                            <th scope="col">Leave Status</th>

                        </tr>
                    </thead>
                    <tbody style={{ fontWeight: 500, fontSize: 15 }} key="tbody">
                        {myleave && myleave?.map((leave: any) => {
                            return (
                                <tr>
                                    <td>{leave.employee_id}</td>
                                    <td>{leave.leave_type}</td>
                                    <td>{leave.apply_date.split("T")[0]}</td>
                                    <td>{leave.start_date.split("T")[0]}</td>
                                    <td>{leave.end_date.split("T")[0]}</td>
                                    <td>{leave.leave_durations}</td>
                                    <td><div className="comments-scroll">{leave.leave_reason}</div></td>
                                    <td>{leave.leave_status}</td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>
            </div>
        </>
    )
}

export default LeavePage