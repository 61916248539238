import 'font-awesome/css/font-awesome.min.css';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import React, { useState, useReducer } from "react";
import axios from 'axios';
import './registration.css'
import 'font-awesome/css/font-awesome.min.css';
import LoginReducer from '../../redux/reducers/LoginReducer';
import Navigation from '../Navigation/Navigation';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../userRedux/actions';
import { environment } from '../../env';
import moment from 'moment';

const UserLogin = (props: any) => {

    const [errormessage, setErrormessage] = useState("");
    const navigate = useNavigate();
    const [userData, dispatchUserData] = useReducer(LoginReducer, [] as any);
    const [passwordShown, setPasswordShown] = useState(false);
    const dispatch = useDispatch()

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const submitform = async (logindata: object) => {
        try {
            const res = await axios.post(`${environment.baseUrl}login/`, logindata);
            if (res.data.status !== 'error') {
                setErrormessage("");
                Logindata(res.data.employee_id, res.data.userid);
                localStorage.setItem('employee_id', res.data.employee_id);
                localStorage.setItem('userid', res.data.userid);
                dispatch(loginAction(true))
                props.login()
            }
            else {
                setErrormessage("* invalid credentials");
            }
        } catch (err) {
            console.log("err");
        }
    };
    const Logindata = async (employee_id: any, userid: any) => {
        const userdata = {
            employee_id: employee_id,
            user: userid,
            month: moment(new Date()).format('YYYY-MM')
        }
        console.log(userdata)
        try {
            const res = await axios.post(`${environment.baseUrl}employee-attendance/`, userdata);
            const re = await axios.get(`${environment.baseUrl}ipaddress/`);
        }
        catch (err) {
            console.log("err");
        }
    }
    return (
        <><Navigation/> 
        <div className="container-fluid">
            <div className="form_wrapper shadow" >
                <div className="form_container">
                    <div className="title_container">
                        <h2 className="base-font-bold base-text-color">Sign In</h2>
                    </div>
                    <div className="clearfix">
                        <div className="">
                            <form onSubmit={handleSubmit(submitform)}>
                                <div className="d-flex justify-content-between align-items-center input-border p-0 mb-4">
                                    <i className="fa-solid fa-envelope fs-4"></i>
                                    <input
                                    className="shadow-none border-0 outline-none px-2 base-font-600 ms-1"
                                        type="email"
                                        placeholder="Email"
                                        {...register("email_id", { required: true })}
                                    />
                                </div>
                                {errors.email && <span className='error'> * Email Id is required</span>}
                                <div className="d-flex justify-content-between align-items-center input-border p-0 mb-4">
                                    <i className="fa-solid fa-lock fs-4 text-black"></i>
                                    <input
                                    className="shadow-none border-0 outline-none px-2 base-font-600 ms-1"
                                        type="password"
                                        {...register("password", { required: true })}
                                        placeholder="Password"
                                    />
                                </div>
                                <p style={{textAlign:'center', color:'red'}}>{errormessage}</p>
                                <input className="base-font-600 base-bg-color align-self-center" type="submit" defaultValue="Register" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}


export default UserLogin
