import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { environment } from '../../../env';
import swal from 'sweetalert'
import '../Basic_Details/BasicDetails.css'
import { Button, Card, CardBody, CardFooter, Label, CardTitle, Form, Input } from 'reactstrap';

interface ExperienceData {
    employee_id: any,
    company_name: any,
    position: any,
    joining_date: any,
    leaving_date: any,
    file: any
}

const Experience = () => {
    const EmpId = localStorage.getItem('employee_id')
    const [data, setData] = useState<ExperienceData[]>([])
    const [show, setShow] = useState(false)
    const [upload, setupload] = useState<any>()
    const [UploadingDetails, setUploadingDetails] = useState(
        {
            Emp_Id: '',
            company_name: '',
            position: '',
            joining_date: '',
            leaving_date: '',
            file: ''
        }
    );

    const handleOnChange = (event: any) => {

        setUploadingDetails({
            ...UploadingDetails,
            [event.target.name]: event.target.value
        });
    }

    const Emp_Id = localStorage.getItem('employee_id')

    const handleOnFileChange = (e: any) => {
        const fileList = e.target.files;
        setupload(fileList[0])
    };

    const postexperience = async (document: any) => {
        let form_data = new FormData();
        form_data.append('employee_id', '')
        form_data.append('company_name', UploadingDetails.company_name)
        form_data.append('position', UploadingDetails.position)
        form_data.append('joining_date', UploadingDetails.joining_date)
        form_data.append('leaving_date', UploadingDetails.leaving_date)
        if (upload && upload.name) {
            form_data.append('file', upload, upload.name)
        }

        try {
            const res = await axios.post(`${environment.baseUrl}experience-post/${Emp_Id}`, form_data, {

            });
            return res.data
        }
        catch (err) {
            console.log('err');
            return err
        }
        return
    };

    const onSubmit = async (data: any) => {
        postexperience(data).then((docResponse) => {

            if (docResponse.status === 'success') {
                swal({
                    title: "Success",
                    text: 'Experience Details Added Sucessfully',
                    icon: "success",
                }).then(() => {
                    GetExperience()
                    setShow(!show)
                })
            }
        });
    }

    const GetExperience = async () => {
        await axios.get(`${environment.baseUrl}get-experience-by-id/${EmpId}`).then(res => {
            setData(res.data);
        })
            .catch(res => console.log("Getting Table error"))
    };

    const ExamplePDFViewer = (file: any) => {
        window.open(`${environment.imageUrl}${file}`);
    };

    useEffect(() => {
        GetExperience()
    }, [])

    return (
        <>
            {show ?

                <>
                    <div style={{ paddingLeft: "20px", paddingRight: "20px" }} className='mt-3'>
                        <div className="d-sm-flex align-items-center justify-content-between mb-4" >
                            <h1 className="fs-3 mb-0  base-font-600 base-text-color mb-0">Experience Letter</h1>
                            <Link to="#">
                                <Button className='ml-3 base-font-600 base-bg-color text-white mt-1 border-0 py-2 shadow-none'
                                ><i className="fa-solid fa-arrow-up-from-bracket fs-6 pe-2"></i>Add Experience Letter</Button>
                            </Link>
                        </div>
                    </div>
                    {/* <table style={{ fontSize: 19 }} className="table table-hover">
                            <thead key="thead">
                                <tr> */}

                    <div>
                        <Card className='mt-3 mx-3 mb-5 card'>
                            <CardTitle className='BDcardtitle BD-tables-head d-flex justify-content-between align-items-center px-3 py-3'>
                                <h4 className="mb-0 base-font-700 base-text-color">Add Experience</h4>
                            </CardTitle>
                            <CardBody >
                                <Form>
                                    <div className='BaDdiv d-flex justify-content-left px-2 gap-5'
                                    //  style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", gap: 20, marginTop: '-35px' }}
                                    >
                                        <div className="width-left w-50">
                                            <Label className='BDLabels base-font-600'>
                                                Company Name
                                            </Label>
                                            <Input
                                                className='py-1 fs-6 shadow-none input-border'
                                                type="text"
                                                id="company_name"
                                                name="company_name"
                                                value={UploadingDetails.company_name}
                                                onChange={handleOnChange}
                                                placeholder="Comapny Name">
                                            </Input>
                                            <Label className='BDLabels base-font-600'>
                                                Joining Date
                                            </Label>
                                            <Input
                                                className='py-1 fs-6 shadow-none input-border'
                                                type="date"
                                                id="Joiningdate"
                                                name="joining_date"
                                                value={UploadingDetails.joining_date}
                                                onChange={handleOnChange}
                                                placeholder="Joining Date">
                                            </Input>

                                        </div>
                                        <div className="width-right" style={{ width: "50%" }}>
                                            <Label className='BDLabels pl-1 base-font-600'>
                                                Position
                                            </Label>
                                            <Input
                                                className='py-1 fs-6 shadow-none input-border'
                                                type="text"
                                                id="Position"
                                                name="position"
                                                value={UploadingDetails.position}
                                                onChange={handleOnChange}
                                                placeholder="Position">
                                            </Input>
                                            <Label className='BDLabels pl-1 base-font-600'>
                                                Leaving Date
                                            </Label>
                                            <Input
                                                className='py-1 fs-6 shadow-none input-border'
                                                type="date"
                                                id="leavingdate"
                                                name="leaving_date"
                                                value={UploadingDetails.leaving_date}
                                                onChange={handleOnChange}
                                                placeholder="Leaving Date   ">
                                            </Input>
                                        </div>
                                    </div>
                                    <Label className='BDLabels base-font-600 ps-2'>
                                        Upload Document
                                    </Label>
                                    <Input
                                        className='py-1 ms-2 fs-6 shadow-none input-border w-100'
                                        accept='.pdf'
                                        type="file"
                                        id="file"
                                        name="file"
                                        onChange={(e) => { handleOnFileChange(e) }}
                                    >
                                    </Input>
                                    <p className='mt-2 ms-2'><span className='fw-bold text-danger fs-6'>Note : Accepts only PDF File*</span></p>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                    <div className='d-flex justify-content-end gap-3'>
                                <Button className='bg-info border-0 px-4 outline-none base-font-600 fw-bold py-2 fs-6' onClick={onSubmit}
                                >
                                   <i className="fa-regular fa-pen-to-square pe-1"></i>  Upload
                                </Button>
                                <Button className='bg-danger border-0 px-4 outline-none base-font-600 fw-bold py-2 fs-6'
                              onClick={() => setShow(!show)}

                                >
                                   <i className="fa-regular fa-trash-can ps-1"></i>  Cancel
                                </Button>
                            </div>
                            </CardFooter>
                        </Card>
                    </div>
                </>
                : < >
                    <div style={{ paddingLeft: "20px", paddingRight: "20px" }} className='mt-3'>
                        <div className="d-sm-flex align-items-center justify-content-between mb-4" >
                            <h1 className="fs-3 mb-0  base-font-600 base-text-color"
                            >Experience</h1>
                            {/* <Link to={'addexperience'}> */}
                            <Button  className='ml-3 base-font-600 base-bg-color text-white mt-1 border-0 py-2 shadow-none' onClick={() => setShow(!show)}
                                style={{ marginTop: "10px" }}
                            > <i className="fa-solid fa-arrow-up-from-bracket fs-6 pe-1"></i>  Add Experience Details</Button>
                            
                            {/* </Link> */}
                        </div>
                        <table style={{ fontSize: 19 }} className="table table-hover">
                            <thead key="thead">
                                <tr>

                                    <th scope="col" className='Experience-th BD-experience-table-tr fs-14'> Id </th>
                                    <th scope="col" className='Experience-th BD-experience-table-tr fs-14'>Company Name</th>
                                    <th scope="col" className='Experience-th BD-experience-table-tr fs-14'>Position</th>
                                    <th scope="col" className='Experience-th BD-experience-table-tr fs-14'>Joining Date</th>
                                    <th scope="col" className='Experience-th BD-experience-table-tr fs-14'>Leaving Date</th>
                                    <th scope="col" className='Experience-th BD-experience-table-tr fs-14'>Documents</th>
                                </tr>
                            </thead>
                            <tbody style={{ fontWeight: 500, fontSize: 15 }} key="tbody">
                                {data && data?.map((dat: any) => {
                                    let id = 0
                                    return (
                                        <tr>
                                            <td >{id = id + 1}</td>
                                            <td >{dat.company_name}</td>
                                            <td  >{dat.position}</td>
                                            <td  >{dat.joining_date}</td>
                                            <td  >{dat.leaving_date}</td>
                                            <td ><Link onClick={() => ExamplePDFViewer(dat.file)} to=''>View</Link></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </>
    )
}

export default Experience