import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { environment } from '../env';

interface UserData2 {
    date: any;
    day: any;
    login_time: any;
    logout_time: any;
    comment: any;
    remark: any
}

function DashBoard() {
    const [posts, setPost] = useState({
        rewards: '',
        previlage_leave: 0,
        sick_leave: 0,
        comp_off: 0,
        late_marks:0,
        early_leave:0,
        over_time:0
    });;
    const [att, setAtt] = useState<UserData2[]>([])
    const EmpId = localStorage.getItem('employee_id')
    let arr: any[] = []

    const EmployeeDetails = async () => {
        await axios.get(`${environment.baseUrl}user-details/${EmpId}`)
            .then(res => {
                setPost(res.data);
            })
            .catch(res => console.log("error"))
    }

    const EmployeeAttendance = () => {
        axios.get(`${environment.baseUrl}get-employee-attendance/${EmpId}`)
            .then(res => {
                setAtt(res.data);
            })
            .catch(res => console.log("error"));
    };

    useEffect(() => {
        EmployeeDetails(),
            EmployeeAttendance()
    }, []);

    return (
        <>
        <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-5">
                            <h1 className="h3 mb-0 base-text-color base-font-bold pt-2 ps-3" >Dashboard</h1>
                        </div>   
                <div className='row px-3'>
                <div className="col-xl-3 col-md-6 mb-5 ml-0 w-100 pl-2">
                    <div className="card border-bottom-yellow w-100 bg-gradient-light shadow h-100 py-0">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h5 mb-0 mt-2 font-weight-bold text-black base-font-bold">
                                        Privilage Leaves
                                    </div>
                                    <div className="h5 pt-2  pl-0 text-black font-weight-bold text-uppercase mb-1">
                                        {posts.previlage_leave} / 12
                                    </div>
                                </div>
                                <div className="col-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-5 w-25 ml-0 pl-2">
                    <div className="card border-bottom-info bg-gradient-light shadow rounded h-100 py-0">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-0">
                                    <div className="h5 mb-0 mt-2 font-weight-bold text-black base-font-bold">
                                        Comp-Off
                                    </div>
                                    <div className="h5 pt-2  pl-0 text-black font-weight-bold text-uppercase mb-1">
                                        {posts.comp_off}
                                    </div>
                                </div>
                                <div className="col-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-5 ml-0 w-25 pl-2">
                    <div className="card border-bottom-danger bg-gradient-light shadow h-100 py-0">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h5 mb-0 mt-2 font-weight-bold text-black base-font-bold">
                                        Sick Leaves
                                    </div>
                                    <div className="h5 pt-2  pl-0 text-black font-weight-bold text-uppercase mb-1">
                                        {posts.sick_leave} / 5
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fa-regular fa-face-mask fa-2x text-black-600"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-5 w-25 ml-0 pl-2">
                    <div className="card border-bottom-dark bg-gradient-light shadow h-100 py-0">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h5 mb-0 mt-2 font-weight-bold text-black base-font-bold">
                                        Week-Off
                                    </div>
                                    <div className="h5 pt-2  pl-0 text-black font-weight-bold text-uppercase mb-1">
                                        4
                                    </div>
                                </div>
                                <div className="col-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-5 w-25 ml-0 pl-2">
                    <div className="card border-bottom-skyblue bg-gradient-light shadow h-100 py-0">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h5 mb-0 mt-2 font-weight-bold text-black base-font-bold">
                                        Late Marks
                                    </div>
                                    <div className="h5 pt-2  pl-0 text-black font-weight-bold text-uppercase mb-1">
                                        {posts.late_marks}
                                    </div>
                                </div>
                                <div className="col-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-5 w-25 ml-0 pl-2">
                    <div className="card  bg-gradient-light shadow h-100 py-0 border-bottom-pink">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h5 mb-0 mt-2 font-weight-bold text-black base-font-bold">
                                        Early Leave
                                    </div>
                                    <div className="h5 pt-2  pl-0 text-black font-weight-bold text-uppercase mb-1">
                                        {posts.early_leave}
                                    </div>
                                </div>
                                <div className="col-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-5 w-25 ml-0 pl-2">
                    <div className="card border-bottom-primary bg-gradient-light shadow h-100 py-0">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h5 mb-0 mt-2 font-weight-bold text-black base-font-bold">
                                        Over Time
                                    </div>
                                    <div className="h5 pt-2  pl-0 text-black font-weight-bold text-uppercase mb-1">
                                    {posts.over_time/60}<span className='h6 text-black font-weight-bold text-uppercase ml-2   '>hrs</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-5 ml-0 pl-2">
                    <div className="card border-bottom-success bg-gradient-light shadow h-100 py-0">
                        <div className="card-body ">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h5 mb-0 mt-2 font-weight-bold text-black base-font-bold">
                                        Rewards
                                    </div>
                                    <div className="h5 pt-2  pl-0 text-black font-weight-bold text-uppercase mb-1">
                                        {posts.rewards}
                                    </div>
                                </div>
                                <div className="col-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                   
            </div>
        </>
    )
}

export default DashBoard