import React, { useState, useEffect } from 'react';
import '../Basic_Details/BasicDetails.css'
import swal from "sweetalert";
import axios from 'axios';
import { Button, Card, CardBody, CardFooter, CardTitle, Input, Label } from 'reactstrap'
import { environment } from '../../../env';

const BankDetails = (props: any) => {

    const [userDetail, setUserDetail] = useState(
        {
            bank_name: props.details.bank_name,
            acc_holder_name: props.details.acc_holder_name,
            acc_number: props.details.acc_number,
            acc_ifsc_number: props.details.acc_ifsc_number,
            acc_type: props.details.acc_type,
            acc_branch: props.details.acc_branch
        }
    );
    let [show, setShow] = useState(true);

    const handleOnChange = (e: any) => {
        setUserDetail({
            ...userDetail,
            [e.target.name]: e.target.value
        });
        console.log(e.target.name, "=", e.target.value)
        console.log(userDetail)
    }

    const submit = (e: any) => {
        e.preventDefault();
        // axios.post('url', {
        axios.put(`${environment.baseUrl}update-basic-details/${props.details.employee_id}`, {
            bank_name: `${userDetail.bank_name}`,
            acc_holder_name: `${userDetail.acc_holder_name}`,
            acc_number: `${userDetail.acc_number}`,
            acc_ifsc_number: `${userDetail.acc_ifsc_number}`,
            acc_type: `${userDetail.acc_type}`,
            acc_branch: `${userDetail.acc_branch}`
        }).then(
            res => {
                if (res.status == 200) {
                    swal({
                        title: "Success",
                        text: 'Bank Details is updated successfully',
                        icon: "success",
                    })
                    props.update(!props.update);
                    setShow(!show);
                }

            }
        ).catch(res => console.log("error"))
    }

    return (

        <div>
            <Card className="mt-3 mx-3 mb-5 card"
            // style={{ background: "white", width: "95%", marginLeft: "36px", height: "1000%" }}
            >

                <CardTitle className='BDcardtitle BD-tables-head d-flex justify-content-between align-items-center px-3 py-3'>
                    <h4 className="mb-0 base-font-700 base-text-color"> Bank Account Details</h4>
                    <div className=''>
                                {show && <Button className='base-bg-color border-0 px-4 outline-none base-font-600 fw-bold base-font-600 fw-bold'  onClick={() => { setShow(!show) }}

                                >
                                    <i className="fa-regular fa-pen-to-square" style={{ marginRight: "5px" }}></i>  EDIT
                                </Button>}
                            </div>
                </CardTitle>
                {show ?
                    <>
                        <CardBody className='BDcardbody '>
                            <div className='BaDdiv d-flex justify-content-left px-3'
                            // style={{ justifyContent: "space-around", flexDirection: "row", display: "flex", gap: 20, marginTop: '-55px' }}
                            >
                                <div className="width-left w-50 "
                                //  style={{ width: "50%" }}
                                >
                                    <Label className='BDLabels base-font-600 fs-6  '>
                                        Bank Name
                                    </Label><br />
                                    <Label className='BDLabelInput'>
                                        {userDetail.bank_name == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : userDetail.bank_name}

                                    </Label><br />
                                    <Label className='BDLabels base-font-600 fs-6  '>
                                        Account Number
                                    </Label><br />
                                    <Label className='BDLabelInput'>
                                        {userDetail.acc_number == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : userDetail.acc_number}

                                    </Label><br />
                                    <Label className='BDLabels base-font-600 fs-6  '>
                                        Account Type
                                    </Label>
                                    <br />
                                    <Label className='BDLabelInput'>
                                        {userDetail.acc_type == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : userDetail.acc_type}

                                    </Label><br />
                                </div>
                                <div
                                // className="width-right" style={{ width: "50%" }}
                                >
                                    <Label className='BDLabels base-font-600 fs-6  '>

                                        Account Holder's Name
                                    </Label><br />
                                    <Label className='BDLabelInput'>
                                        {userDetail.acc_holder_name == 'null' ? <span className="d-flex justify-content-center w-100"> - </span> : userDetail.acc_holder_name}

                                    </Label><br />
                                    <Label className='BDLabels base-font-600 fs-6  '>

                                        IFSC Code
                                    </Label><br />
                                    <Label className='BDLabelInput'>
                                        {userDetail.acc_ifsc_number == 'null' ? <span className="d-flex justify-content-center w-75"> - </span> : userDetail.acc_ifsc_number}

                                    </Label><br />
                                    <Label className='BDLabels base-font-600 fs-6  '>

                                        Branch Name
                                    </Label><br />
                                    <Label className='BDLabelInput'>
                                        {userDetail.acc_branch == 'null' ? <span className="d-flex justify-content-center w-75"> - </span> : userDetail.acc_branch}

                                    </Label><br />
                                </div>
                            </div>
                        </CardBody>
                    </> :
                    <>
                        <form id='details' onSubmit={(e) => { submit(e) }}>
                            <CardBody className='BDcardbody '>
                                <div className='BaDdiv d-flex justify-content-left px-2 gap-5'>
                                    <div className="width-left w-50" >
                                        <Label className='BDLabels base-font-600 fs-6  '>Bank Name
                                        </Label>
                                        <Input
                                           className="py-1 fs-6 shadow-none input-border"
                                            type="text"
                                            id="Bank_name"
                                            name="bank_name"
                                            value={userDetail.bank_name == 'null' ? userDetail.bank_name = '' : userDetail.bank_name}
                                            onChange={handleOnChange}
                                            placeholder="Bank Name">
                                        </Input>
                                        <Label className='BDLabels base-font-600 fs-6  '>

                                            Account Number
                                        </Label>
                                        <Input
                                           className="py-1 fs-6 shadow-none input-border"
                                            type="text"
                                            id="acc_number"
                                            name="acc_number"
                                            value={userDetail.acc_number == 'null' ? userDetail.acc_number = '' : userDetail.acc_number}
                                            onChange={handleOnChange}
                                            placeholder="Account Number">
                                        </Input>
                                        <Label className='BDLabels base-font-600 fs-6  '>

                                            Account Type
                                        </Label>
                                        <Input
                                           className="py-1 fs-6 shadow-none input-border"
                                            type="text"
                                            id="acc_type"
                                            name="acc_type"
                                            value={userDetail.acc_type == 'null' ? userDetail.acc_type = '' : userDetail.acc_type}
                                            onChange={handleOnChange}
                                            placeholder="Account Type">
                                        </Input>

                                    </div>
                                    <div className="width-right w-50" >
                                        <Label className='BDLabels base-font-600 fs-6  '>Account Holder's Name
                                        </Label>
                                        <Input
                                           className="py-1 fs-6 shadow-none input-border"
                                            type="text"
                                            id="acc_holder_name"
                                            name="acc_holder_name"
                                            value={userDetail.acc_holder_name == 'null' ? userDetail.acc_holder_name = '' : userDetail.acc_holder_name}
                                            onChange={handleOnChange}
                                            placeholder="Account Holder's Name">
                                        </Input>
                                        <Label className='BDLabels base-font-600 fs-6  '>
                                            IFSC Code
                                        </Label>
                                        <Input
                                           className="py-1 fs-6 shadow-none input-border"
                                            type="text"
                                            id="acc_ifsc_number"
                                            name="acc_ifsc_number"
                                            value={userDetail.acc_ifsc_number == 'null' ? userDetail.acc_ifsc_number = '' : userDetail.acc_ifsc_number}
                                            onChange={handleOnChange}
                                            placeholder="IFSC Code">
                                        </Input>
                                        <Label className='BDLabels base-font-600 fs-6  '>
                                            Branch Name
                                        </Label>
                                        <Input
                                           className="py-1 fs-6 shadow-none input-border"
                                            type="text"
                                            id="branch_name"
                                            name="acc_branch"
                                            value={userDetail.acc_branch == 'null' ? userDetail.acc_branch = '' : userDetail.acc_branch}
                                            onChange={handleOnChange}
                                            placeholder="Branch Name">
                                        </Input>
                                    </div>
                                </div>

                            </CardBody>
                            <CardFooter>
                                    <div className='d-flex justify-content-end gap-3'>
                                <Button className='bg-info border-0 px-4 outline-none base-font-600 fw-bold py-2 fs-6'  

                                >
                                   <i className="fa-regular fa-pen-to-square pe-1"></i>  Update
                                </Button>
                                <Button className='bg-danger border-0 px-4 outline-none base-font-600 fw-bold py-2 fs-6'
                              onClick={() => { setShow(!show) }}

                                >
                                   <i className="fa-regular fa-trash-can ps-1"></i>  Cancel
                                </Button>
                            </div>
                            </CardFooter>
                        </form>
                    </>}
            </Card>
        </div>

    )
}

export default BankDetails