import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import axios from 'axios';
import { Card } from '@mui/material';
import { truncate } from 'fs/promises';
import { environment } from '../../../env';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form';
import { ModalTitle } from 'react-bootstrap';

interface UserData {
    firstname: any;
    lastname: any;
}

const ApplyLeaveModel = (props: any) => {
    const url = "";
    const EmpId = localStorage.getItem('employee_id');
    const userid = localStorage.getItem('userid');
    const [modal, setModal] = useState(false);
    let [show, setShow] = useState(true);
    let [time, setTime] = useState(false);
    const [post, setPost] = useState<UserData>({
        firstname: '',
        lastname: ''
    });

    const [leavedata, setLeaveData] = useState(
        {
            employee_id: EmpId,
            leave_type: '',
            start_date: '',
            end_date: '',
            leave_durations: '',
            leave_reason: '',
            user: userid
        }
    );
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();


    const toggle = () => setModal(!modal);

    const handleOnChange = (event: any) => {

        setLeaveData({
            ...leavedata,
            [event.target.name]: event.target.value
        });
    }

    const hide = (e: any) => {
        if (e.target.name === 'leave_durations') {
            if (e.target.value === "halfday") {
                setShow(true)
            }
            else if (e.target.value === "fullday") {
                setShow(true)
            }
            else if (e.target.value === "aboveaday") {
                setShow(false)
            }
        }

    };

    const functioncall = (e: any) => {
        handleOnChange(e);
        hide(e);
    }
    const OnSubmit = async () => {
        try {
            if (leavedata.end_date == '') {
                leavedata.end_date = leavedata.start_date
            }

            await axios.post(`${environment.baseUrl}apply-leave`, leavedata).then((leave) => {
                if (leave.data.status === 'success') {
                    swal({
                        title: "Success",
                        text: 'Applied For Leave Successfully',
                        icon: "success",

                    }).then(() => {
                        props.Leaves();
                        reset({
                            employee_id: EmpId,
                            leave_type: '',
                            start_date: '',
                            end_date: '',
                            leave_durations: '',
                            leave_reason: '',
                        });
                    })
                }
            })
        }
        catch (err) {
            console.log("Leave error");
        }
    };

    return (
        <div>
            <Form inline onSubmit={(e) => e.preventDefault()}>
                <Button className='ml-3  base-bg-color text-white mt-1 border-0 py-2 base-font-600 shadow-none' onClick={toggle}>
                <i className="fa-regular fa-file-lines fs-6 pe-1"></i>  Apply Leave
            </Button>
            </Form>
            <Modal
                isOpen={modal}
                toggle={toggle}
                style={{ fontFamily: "Poppins", maxWidth: "28%" }}
                className="shadow-none"
            >
                <ModalHeader toggle={toggle} style={{ fontWeight: 600, color: 'primary' }} className="d-flex align-items-center">
                    <ModalTitle className="base-font-500 base-text-color">Apply For Leave
                    </ModalTitle>
                </ModalHeader>
                <ModalBody style={{ backgroundImage: 'linear-gradient(to bottom right, white, white)', fontWeight: 400, fontFamily: 'Nunito' }}>

                    <Form id='details' onSubmit={handleSubmit(OnSubmit)}>
                        <label htmlFor="employee_id" className="base-font-600 text-black" >
                            Employee ID :
                        </label>
                        <label htmlFor="employee_id" className="base-font-500 text-black ps-2 pb-1" >
                            {EmpId}
                        </label>
                        <div className="">
                            <label htmlFor="leave_type" className="base-font-600 text-black">
                                Leave Type :
                            </label><br />
                            <select id="leave_type" name="leave_type" value={leavedata.leave_type} onChange={handleOnChange} className="w-100 py-1 fs-6 shadow-none input-border">
                                <option value="">Options...</option>
                                <option value="CompOff">Comp Off</option>
                                <option value="Sick Leave">Sick Leave</option>
                                <option value="Privilage Leave">Privilage Leave</option>
                                <option value="UnPaid Leave">Un-Paid Leave</option>
                            </select>
                        </div>
                        <div >
                            <label htmlFor="leave_durations" className="base-font-600 text-black pt-3" >
                                Leave Duration :
                            </label><br />
                            <input
                                type="radio"
                                id="halfday"
                                name='leave_durations'
                                value="halfday"
                                checked={leavedata.leave_durations === "halfday"}
                                onChange={functioncall}
                                className="py-1 fs-6 shadow-none input-border"
                            /><span style={{ marginBottom: "20px", marginLeft: "5px", color: 'black' }}>Half Day</span>
                            <input
                                type="radio"
                                id="fullday"
                                name='leave_durations'
                                value="fullday"
                                checked={leavedata.leave_durations === "fullday"}
                                onChange={functioncall}
                                style={{ paddingLeft: '1rem', marginLeft: "20px" }}
                            /><span style={{ marginBottom: "20px", marginLeft: "5px", color: 'black' }}>Full Day</span>
                            <input
                                type="radio"
                                id="aboveaday"
                                name='leave_durations'
                                value="aboveaday"
                                checked={leavedata.leave_durations === "aboveaday"}
                                onChange={functioncall}
                                style={{ paddingLeft: '1rem', marginLeft: "20px" }}
                            /><span style={{ marginBottom: "20px", marginLeft: "5px", color: 'black' }}>Above a Day</span>
                            <div >
                            </div>
                        </div>
                        <div >
                            {show ?
                                <><label htmlFor="start_date" className="base-font-600 text-black pt-3" >
                                    Date :
                                </label><input
                                        style={{ width: "100%", fontSize: 14 }}
                                        className='py-1 fs-6 shadow-none input-border'
                                        id="start_date"
                                        name="start_date"
                                        type="date"
                                        value={leavedata.start_date}
                                        onChange={handleOnChange} />
                                </>
                                : <>
                                    <label htmlFor="start_date" className="base-font-600 text-black pt-3" >
                                        From Date :
                                    </label><input
                                        style={{ width: "100%", fontSize: 14 }}
                                        className='form-control'
                                        id="start_date"
                                        name="start_date"
                                        type="date"
                                        value={leavedata.start_date}
                                        onChange={handleOnChange} />
                                    <label htmlFor="end_date" className="base-font-600 text-black pt-3" >
                                        To Date :
                                    </label><input
                                        style={{ width: "100%", fontSize: 14 }}
                                        className='py-1 fs-6 shadow-none input-border'
                                        id="end_date"
                                        name="end_date"
                                        type="date"
                                        value={leavedata.end_date}
                                        onChange={handleOnChange} />
                                </>}<br />
                        </div>
                        <div >
                            <label htmlFor="leave_reason" className="base-font-600 text-black pt-2 pb-1" >
                                Reason of Leave:
                            </label>
                            <Input
                                className='py-1 fs-6 shadow-none input-border-rounded px-1'
                                id="leave_reason"
                                name="leave_reason"
                                placeholder="Reason for Leave.."
                                type="textarea"
                                value={leavedata.leave_reason}
                                onChange={handleOnChange}
                            /><br />
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter
                    className="py-2"
                >
                    <Button
                        type="submit"
                        form="details"
                        value="Submit"
                        onClick={toggle}
                        className="base-bg-color text-white mt-1 border-0 py-2 base-font-600 shadow-none"
                    >Submit</Button>{' '}
                    <Button  onClick={toggle}
                        className="btn-secondary text-white mt-1 border-0 py-2 base-font-600 shadow-none"
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ApplyLeaveModel;



