import { useEffect, useState } from 'react'
import { Card, CardBody, CardFooter, CardTitle, Input, Button, Form, Label } from 'reactstrap'
import axios from 'axios'
import { environment } from '../../../env';
import swal from "sweetalert";
import '../Basic_Details/BasicDetails.css'

const Address = (props: any) => {
    let EmpId = localStorage.getItem("employee_id");
    const [userData, setUserData] = useState({
        address: props.details.address,
        city: props.details.city,
        state: props.details.state,
        country: props.details.country,
        district: props.details.district,
        pincode: props.details.pincode
    });

    const toggle = () => { setShow(!show) }
    const [show, setShow] = useState(true)


    const submit = (e: any) => {
        e.preventDefault();
        // axios.post('url', {
        axios.put(`${environment.baseUrl}update-basic-details/${EmpId}`, {
            address: userData.address,
            state: userData.state,
            country: userData.country,
            city: userData.city,
            district: userData.district,
            pincode: userData.pincode

        }).then(
            res => {
                if (res.status == 200) {
                    swal({
                        title: "Success",
                        text: 'Address Updated Successfully',
                        icon: "success",
                    })
                    setShow(!show)
                    props.update(true)
                }
            }
        ).catch(res => console.log("error"))
    }
    useEffect(() => {

    }, [userData])

    const handleOnChange = (event: any) => {
        setUserData({
            ...userData,
            [event.target.name]: event.target.value
        });
    }

    return (
        <div>
            <Card className='mt-3 mx-3 mb-5 card'>
                <CardTitle className='BDcardtitle BD-tables-head d-flex justify-content-between align-items-center px-3 py-3'>
                    <h4 className="mb-0 base-font-700 base-text-color">Address</h4>
                    <div className=''>
                              {show &&  <Button className='base-bg-color border-0 px-4 outline-none base-font-600 fw-bold base-font-600 fw-bold'  onClick={toggle}

                                >
                                    <i className="fa-regular fa-pen-to-square" style={{ marginRight: "5px" }}></i>  EDIT
                                </Button>}
                            </div>
                </CardTitle>
                {show ?
                    <>
                        <CardBody>
                            <div >
                                <div className="p-2">
                                    <Label className="BDLabels m-0 base-font-600 fs-6 pb-2 ps-1" >Address</Label>
                                    <Label className="BDLabelInput input-border w-100 pl-2 BD-labels-font " > {userData.address} </Label>
                                </div>
                            </div>
                            <div className='d-flex row' >
                                <div className=" col-4">
                                    <div className="p-2">
                                        <Label className="BDLabels m-0 base-font-600 fs-6 pb-2 ps-1"   >City</Label>
                                        <Label className="BDLabelInput input-border w-100 pl-2 BD-labels-font"  > {userData.city} </Label>
                                    </div>
                                </div>

                                <div className=" col-4">
                                    <div className="p-2">
                                        <Label className="BDLabels m-0 base-font-600 fs-6 pb-2 ps-1"   >District</Label>
                                        <Label className="BDLabelInput input-border w-100 pl-2 BD-labels-font"  > {userData.pincode} </Label>
                                    </div>
                                </div>

                                <div className=" col-4">
                                    <div className="p-2">
                                        <Label className="BDLabels m-0 base-font-600 fs-6 pb-2 ps-1"   >State</Label>
                                        <Label className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"  > {userData.state} </Label>
                                    </div>
                                </div>
                                <br />
                                <div className=" col-4">
                                    <div className="p-2">
                                        <Label className="BDLabels m-0 base-font-600 fs-6 pb-2 ps-1"   >Pin Code</Label>
                                        <Label className="BDLabelInput input-border w-100 pl-2 BD-labels-font"  > {userData.pincode} </Label>
                                    </div>
                                </div>
                                <div className=" col-4">
                                    <div className="p-2">
                                        <Label className="BDLabels m-0 base-font-600 fs-6 pb-2 ps-1"   >Country</Label>
                                        <Label className="BDLabelInput input-border w-100 pl-2 BD-labels-font"  > {userData.country} </Label>
                                    </div>
                                </div>
                            </div>

                        </CardBody>
                    </>
                    : <>
                        <form id='details' onSubmit={(e) => { submit(e) }}>
                            <CardBody>
                                <div >
                                    <div className="p-2">
                                        <Label className="BDLabels m-0 base-font-600 fs-6 pb-2 ps-1" >Address</Label>
                                        <Input
                                            className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                                            type="text"
                                            id="address"
                                            name="address"
                                            value={userData.address}
                                            onChange={handleOnChange}
                                            placeholder="Addresss"
                                        />
                                    </div>
                                </div>

                                <div className='row d-flex' >
                                    <div className=" col-4">
                                        <div className="p-2">
                                            <Label className="BDLabels m-0 base-font-600 fs-6 pb-2 ps-1"   >City</Label>
                                            <Input
                                                className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                                                type="text"
                                                id="city"
                                                name="city"
                                                value={userData.city}
                                                onChange={handleOnChange}
                                                placeholder="City"

                                            />
                                        </div>
                                    </div>

                                    <div className=" col-4">
                                        <div className="p-2">
                                            <Label className="BDLabels m-0 base-font-600 fs-6 pb-2 ps-1"   >District</Label>
                                            <Input
                                                className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                                                type="text"
                                                id="district"
                                                name="district"
                                                value={userData.district}
                                                onChange={handleOnChange}
                                                placeholder="District"

                                            /></div>
                                    </div>

                                    <div className=" col-4">
                                        <div className="p-2">
                                            <Label className="BDLabels m-0 base-font-600 fs-6 pb-2 ps-1"   >State</Label>
                                            <Input
                                                className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                                                type="text"
                                                id="state"
                                                name="state"
                                                value={userData.state}
                                                onChange={handleOnChange}
                                                placeholder="State"

                                            /></div>
                                    </div>
                                    <div className=" col-4">
                                        <div className="p-2">
                                            <Label className="BDLabels m-0 base-font-600 fs-6 pb-2 ps-1"   >Pin Code</Label>
                                            <Input
                                                className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                                                type="number"
                                                id="pincode"
                                                name="pincode"
                                                value={userData.pincode}
                                                onChange={handleOnChange}
                                                placeholder="Pincode"

                                            /></div>
                                    </div>
                                    <div className=" col-4">
                                        <div className="p-2">
                                            <Label className="BDLabels m-0 base-font-600 fs-6 pb-2 ps-1"   >Country</Label>
                                            <Input
                                                className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                                                type="text"
                                                id="country"
                                                name="country"
                                                value={userData.country}
                                                onChange={handleOnChange}
                                                placeholder="Country"

                                            /></div>
                                    </div>
                                    <br />
                                </div>
                            </CardBody>
                            <CardFooter>
                                    <div className='d-flex justify-content-end gap-3'>
                                <Button className='bg-info border-0 px-4 outline-none base-font-600 fw-bold py-2 fs-6'  

                                >
                                   <i className="fa-regular fa-pen-to-square pe-1"></i>  Update
                                </Button>
                                <Button className='bg-danger border-0 px-4 outline-none base-font-600 fw-bold py-2 fs-6'
                                onClick={() => setShow(!show)}

                                >
                                   <i className="fa-regular fa-trash-can ps-1"></i>  Cancel
                                </Button>
                            </div>
                            </CardFooter>
                        </form>
                    </>
                }

            </Card>
        </div >
    )
}

export default Address