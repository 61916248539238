import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Button, Card, CardBody, CardFooter, CardTitle, Form, Input, Label } from 'reactstrap'
import { environment } from '../../../env';
import swal from 'sweetalert'
import '../Basic_Details/BasicDetails.css'

const FamilyDetails = (props: any) => {
    const [userDetail, setUserDetail] = useState(
        {
            father_name: props.details.father_name,
            father_occupation: props.details.father_occupation,
            father_contact: props.details.father_contact,
            mother_name: props.details.mother_name,
            mother_occupation: props.details.mother_occupation,
            mother_contact: props.details.mother_contact,
            maritial_status: props.details.maritial_status,
            spouse_name: props.details.spouse_name,
            spouse_dob: props.details.spouse_dob,
            number_of_children: props.details.number_of_children,
            spouse_ocupation: props.details.spouse_ocupation,
            spouse_contact_no: props.details.spouse_contact_no
        }
    );
    let [show, setShow] = useState(false)
    let [Fshow, setFShow] = useState(true);

    const handleOnChange = (e: any) => {
        setUserDetail({
            ...userDetail,
            [e.target.name]: e.target.value
        });
        console.log(e.target.name, "=", e.target.value)
        console.log(userDetail)
    }

    const submit = (e: any) => {
        e.preventDefault();
        // axios.post('url', {
        axios.put(`${environment.baseUrl}update-basic-details/${props.details.employee_id}`, {
            father_name: userDetail.father_name,
            father_occupation: userDetail.father_occupation,
            father_contact: userDetail.father_contact,
            mother_name: userDetail.mother_name,
            mother_occupation: userDetail.mother_occupation,
            mother_contact: userDetail.mother_contact,
            maritial_status: userDetail.maritial_status,
            spouse_name: userDetail.spouse_name,
            spouse_dob: userDetail.spouse_dob,
            number_of_children: userDetail.number_of_children,
            spouse_ocupation: userDetail.spouse_ocupation,
            spouse_contact_no: userDetail.spouse_contact_no
        }).then(
            res => {
                if (res.status == 200) {
                    swal({
                        title: "Success",
                        text: 'Family Details updated successfully',
                        icon: "success",
                    })
                    setFShow(!Fshow);
                    props.update(!props.update);
                }

            }
        ).catch(res => console.log("error"))
    }


    return (
        <div>
            <Card className="mt-3 mx-3 mb-5 card">
               
            <CardTitle className='BDcardtitle BD-tables-head d-flex justify-content-between align-items-center px-3 py-3'>
                    <h4 className="mb-0 base-font-700 base-text-color">Family Details</h4>
                    <div className=''>
                               {Fshow && <Button className='base-bg-color border-0 px-4 outline-none base-font-600 fw-bold base-font-600 fw-bold'  onClick={() => setFShow(!Fshow)}

                                >
                                    <i className="fa-regular fa-pen-to-square" style={{ marginRight: "5px" }}></i>  EDIT
                                </Button>}
                            </div>
                </CardTitle>
                {Fshow ?
                    <>
                        <CardBody className='BDcardbody '>

                            <Form
                                id="details" >
                                <div className='BaDdiv d-flex justify-content-left px-lg-3'
                                // style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", gap: 20, marginTop: '5px' }}
                                >
                                    <div className="width-left w-50">
                                        <Label className='BDLabels base-font-600 fs-6 '>
                                            Father's Name
                                        </Label><br />
                                        <Label className='BDLabelInput'>
                                            {userDetail.father_name == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : userDetail.father_name}
                                        </Label><br />
                                        <Label className='BDLabels base-font-600 fs-6 '>
                                            Father's Occupation
                                        </Label>
                                        <br />
                                        <Label className='BDLabelInput'>
                                            {userDetail.father_occupation == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : userDetail.father_occupation}
                                        </Label><br />
                                        <Label className='BDLabels base-font-600 fs-6 '>

                                            Father's Contact Number
                                        </Label>
                                        <br />
                                        <Label className='BDLabelInput'>
                                            {userDetail.father_contact == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : userDetail.father_contact}
                                        </Label><br />
                                    </div>
                                    <div className="width-right w-50" >
                                        <Label className='BDLabels base-font-600 fs-6 '>
                                            Mother's Name
                                        </Label>
                                        <br />
                                        <Label className='BDLabelInput'>
                                            {userDetail.mother_name == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : userDetail.mother_name}
                                        </Label><br />
                                        <Label className='BDLabels base-font-600 fs-6 '>
                                            Mother's Occupation
                                        </Label>
                                        <br />
                                        <Label className='BDLabelInput'>
                                            {userDetail.mother_occupation == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : userDetail.mother_occupation}
                                        </Label><br />
                                        <Label className='BDLabels base-font-600 fs-6 '>
                                            Mother's Contact
                                        </Label>
                                        <br />
                                        <Label className='BDLabelInput'>
                                            {userDetail.mother_contact == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : userDetail.mother_contact}
                                        </Label><br />
                                    </div>
                                </div>
                                <div className='card mt-3 border-0'>
                                    <Label className='BDLabels pl-3 base-font-700'>
                                        Your Maritial Status
                                    </Label>
                                    <Label className='BDLabelInput border-bottom pl-3'>
                                    {userDetail.maritial_status == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : userDetail.maritial_status}
                                    </Label>
                                    {userDetail.maritial_status === "UnMarried" || userDetail.maritial_status === undefined || userDetail.maritial_status === "" ?
                                        <>
                                        </> : <>
                                            <div className='BaDdiv d-flex justify-content-left px-3'>
                                                <div className="width-left w-50" >
                                                    <Label className='BDLabels base-font-600 fs-6 '>
                                                        Spouse Name
                                                    </Label>
                                                    <br />
                                                    <Label className='BDLabelInput'>
                                                        {userDetail.spouse_name == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : userDetail.spouse_name}
                                                    </Label><br />
                                                    <Label className='BDLabels base-font-600 fs-6 '>
                                                        Spouse Occupation
                                                    </Label>
                                                    <br />
                                                    <Label className='BDLabelInput'>
                                                        {userDetail.spouse_ocupation == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : userDetail.spouse_ocupation}
                                                    </Label><br />
                                                    <Label className='BDLabels base-font-600 fs-6 '>
                                                        Number Of Children
                                                    </Label>
                                                    <br />
                                                    <Label className='BDLabelInput'>
                                                        {userDetail.number_of_children == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : userDetail.number_of_children}
                                                    </Label><br />
                                                </div>
                                                <div className="width-right w-50" >
                                                    <Label className='BDLabels base-font-600 fs-6 '>
                                                        Spouse DOB
                                                    </Label>
                                                    <br />
                                                    <Label className='BDLabelInput'>
                                                        {userDetail.spouse_dob == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : userDetail.spouse_dob}
                                                    </Label><br />
                                                    <Label className='BDLabels base-font-600 fs-6 '>
                                                        Spouse Contact Number
                                                    </Label>
                                                    <br />
                                                    <Label className='BDLabelInput'>
                                                        {userDetail.spouse_contact_no == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : userDetail.spouse_contact_no}
                                                    </Label><br />
                                                </div>
                                            </div>
                                        </>}</div>

                            </Form>
                        </CardBody>
                    </>
                    :
                    <>
                        <form id='details' onSubmit={(e) => { submit(e) }}>
                            <CardBody className='BDcardbody '>
                                <div className='BaDdiv d-flex justify-content-left px-2 gap-5'>
                                    <div className="width-left w-50" >
                                        <Label className='BDLabels base-font-600 fs-6 '>

                                            Father's Name
                                        </Label>
                                        <Input  className="py-1 fs-6 shadow-none input-border"
                                            type="text"
                                            id="f_name"
                                            name="father_name"
                                            value={userDetail.father_name == 'null' ? userDetail.father_name = '' : userDetail.father_name}
                                            onChange={handleOnChange}
                                            placeholder="Father's Name">
                                        </Input>
                                        <Label className='BDLabels base-font-600 fs-6 '>

                                            Father's Occupation
                                        </Label>
                                        <Input  className="py-1 fs-6 shadow-none input-border"
                                            type="text"
                                            id="f_occupation"
                                            name="father_occupation"
                                            value={userDetail.father_occupation}
                                            onChange={handleOnChange}
                                            placeholder="Father's Occupation">
                                        </Input>
                                        <Label className='BDLabels base-font-600 fs-6 '>

                                            Father's Contact Number
                                        </Label>
                                        <Input  className="py-1 fs-6 shadow-none input-border"
                                            type="text"
                                            id="f_contact"
                                            name="father_contact"
                                            value={userDetail.father_contact}
                                            onChange={handleOnChange}
                                            placeholder="Father's Contact"
                                        >
                                        </Input>

                                    </div>
                                    <div className="width-right w-50" >
                                        <Label className='BDLabels base-font-600 fs-6 '>

                                            Mother's Name
                                        </Label>
                                        <Input  className="py-1 fs-6 shadow-none input-border"
                                            type="text"
                                            id="m_name"
                                            name="mother_name"
                                            value={userDetail.mother_name}
                                            onChange={handleOnChange}
                                            placeholder="Mother's Name">
                                        </Input>
                                        <Label className='BDLabels base-font-600 fs-6 '>
                                            Mother's Occupation
                                        </Label>
                                        <Input  className="py-1 fs-6 shadow-none input-border"
                                            type="text"
                                            id="m_occupation"
                                            name="mother_occupation"
                                            value={userDetail.mother_occupation}
                                            onChange={handleOnChange}
                                            placeholder="Mother's Occupation">
                                        </Input>
                                        <Label className='BDLabels base-font-600 fs-6 '>
                                            Mother's Contact
                                        </Label>
                                        <Input  className="py-1 fs-6 shadow-none input-border"
                                            type="text"
                                            id="m_contact"
                                            name="mother_contact"
                                            value={userDetail.mother_contact}
                                            onChange={handleOnChange}
                                            placeholder="Mother's Contact Number">
                                        </Input>
                                    </div>
                                </div>
                                <div className='card mt-3 px-1 border-0'>
                                    <div>
                                        <Label className=' text-black base-font-600 fs-6 '>
                                            Maritial Status
                                        </Label><br />
                                        <Input
                                            className='RadioInput fs-5 mx-0 px-0'
                                            type="radio"
                                            id="maritial_status"
                                            name="maritial_status"
                                            value="UnMarried"
                                            checked={userDetail.maritial_status === "UnMarried"}
                                            onChange={handleOnChange}>
                                        </Input>
                                        <Label className='ps-4 pb-1  BaDInput BD-maritial-font fs-6 base-font-600 text-black' >
                                            Un-Married
                                        </Label><br />
                                        <Input
                                            className='RadioInput fs-5 mx-0 px-0'
                                            type="radio"
                                            id="maritial_status"
                                            name="maritial_status"
                                            value="Married"
                                            checked={userDetail.maritial_status === "Married"}
                                            onChange={handleOnChange}
                                        >
                                        </Input>
                                        <Label className='ps-4 pb-1  BaDInput BD-maritial-font fs-6 base-font-600 text-black' >
                                            Married
                                        </Label>
                                    </div>
                                    {show || userDetail.maritial_status === "Married" ?
                                        <>
                                            <div className='BaDdiv d-flex justify-content-left px-3'>
                                                <div className="width-left w-50" >
                                                    <Label className='BDLabels base-font-600 fs-6 '>
                                                        Spouse Name
                                                    </Label>
                                                    <Input className='BaDInput'
                                                        type="text"
                                                        id="s_name"
                                                        name="spouse_name"
                                                        value={userDetail.spouse_name}
                                                        onChange={handleOnChange}
                                                        placeholder="Spose Name">
                                                    </Input>
                                                    <Label className='BDLabels base-font-600 fs-6 '>
                                                        Spouse Occupation
                                                    </Label>
                                                    <Input className='BaDInput'
                                                        type="text"
                                                        id="spouse_ocupation"
                                                        name="spouse_ocupation"
                                                        value={userDetail.spouse_ocupation}
                                                        onChange={handleOnChange}
                                                        placeholder="Spose Occupation">
                                                    </Input>
                                                    <Label className='BDLabels base-font-600 fs-6 '>
                                                        Number Of Children
                                                    </Label>
                                                    <Input className='BaDInput'
                                                        type="text"
                                                        id="number_of_children"
                                                        name="number_of_children"
                                                        value={userDetail.number_of_children}
                                                        onChange={handleOnChange}
                                                        placeholder="Number Of Children">
                                                    </Input>
                                                </div>
                                                <div className="width-right w-50" >
                                                    <Label className='BDLabels base-font-600 fs-6 '>
                                                        Spouse DOB
                                                    </Label>
                                                    <Input className='BaDInput'
                                                        type="date"
                                                        id="s_dob"
                                                        name="spouse_dob"
                                                        value={userDetail.spouse_dob}
                                                        onChange={handleOnChange}
                                                        placeholder="Spouse Dob">
                                                    </Input>
                                                    <Label className='BDLabels base-font-600 fs-6 '>
                                                        Spouse Contact Number
                                                    </Label>
                                                    <Input className='BaDInput'
                                                        type="text"
                                                        id="spouse_contact_no"
                                                        name="spouse_contact_no"
                                                        value={userDetail.spouse_contact_no}
                                                        onChange={handleOnChange}
                                                        placeholder="Spouse Contact Number">
                                                    </Input>
                                                </div>
                                            </div>
                                            <div>

                                            </div>
                                        </> : <></>}
                                </div>
                            </CardBody>
                            <CardFooter>
                                    <div className='d-flex justify-content-end gap-3'>
                                <Button className='bg-info border-0 px-4 outline-none base-font-600 fw-bold py-2 fs-6'  

                                >
                                   <i className="fa-regular fa-pen-to-square pe-1"></i>  Update
                                </Button>
                                <Button className='bg-danger border-0 px-4 outline-none base-font-600 fw-bold py-2 fs-6'
                               onClick={() => { setFShow(!Fshow) }}

                                >
                                   <i className="fa-regular fa-trash-can ps-1"></i>  Cancel
                                </Button>
                            </div>
                            </CardFooter>
                        </form>
                    </>
                }
            </Card >
        </div >
    )
}

export default FamilyDetails