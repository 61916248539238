import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
} from 'reactstrap';
import axios from 'axios';
import swal from 'sweetalert'
import { useForm } from "react-hook-form";
import commentIcon from '../image/message-square.svg'
import moment from 'moment';
import { environment } from '../env';

const Comments = (props: any) => {
  const {
    formState: { errors },
  } = useForm();

  const [modal, setModal] = useState(false);
  const [comments, setcomments] = useState("")
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const toggle = () => { setModal(!modal) };

  const handleOnChange = (event: any) => {
    setcomments(event.target.value);
  }

  const SubmitComment = async (Comment: any) => {
    const UserComment = {
      employee_id: localStorage.getItem('employee_id'),
      date: moment(new Date()).format('YYYY-MM-DD'),
      comment: comments
    }
    try {
      const res = await axios.post(`${environment.baseUrl}comment/`, UserComment).then(
        res => {
          if (res.status == 200) {
            swal({
              title: "Success",
              text: 'Comment Added Successfully',
              icon: "success",
            }).then(
              toggle,
              props.update(true)
            )
          }
        }
      ).catch(res => console.log("error"))

    }
    catch (err) {
      console.log("err");
    }
  };

  return (
    <>
      <Form>
        <FormGroup>
        </FormGroup>{' '}
        <Button className='ml-3 btn  base-bg-color border-none shadow-none' style={{ marginTop: "-20px", width: "80px" }} disabled={props.post !== moment(new Date()).format('YYYY-MM-DD') ? true : false} onClick={toggle}>
          <img src={commentIcon} alt=""  />
        </Button>
      </Form>
      <Modal isOpen={modal} toggle={toggle} unmountOnClose={unmountOnClose}>
        <ModalHeader toggle={toggle} className="base-font-bold">Add Comment</ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            placeholder="Write something..."
            rows={5}
            value={comments}
            onChange={handleOnChange}
            className="shadow-none"
          />
        </ModalBody>
        <ModalFooter>
          <Button className="base-bg-color text-white mt-1 border-0 py-2 base-font-600 shadow-none" onClick={SubmitComment}>
            Submit
          </Button>{' '}
          <Button  className="ml-3 btn-secondary  text-white mt-1 border-0 py-2 base-font-600 shadow-none" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );

}

export default Comments







