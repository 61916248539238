import React, { useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { environment } from '../env';
import RewardsModal from './RewardsModel';
// import RewardsModal from './pages/rewardsModal';


interface Rewards {
    employee_id: any,
    count: any,
    created_date: any,
    file: any
}

const RewardsPage = () => {
    const EmpId = localStorage.getItem('employee_id')
    const [update, setUpdate] = useState(false)
    const [rewards, setRewards] = useState<Rewards[]>([])
    const [userDetails, setUserDetails] = useState(
        {
            rewards: 0
        }
    )


    const GetRewards = async () => {
        await axios.get(`${environment.baseUrl}rewarddocument/${EmpId}`).then(res => {
            setRewards(res.data)
        })
            .catch(res => console.log("Upload doc error"))
    };
    const EmployeeDetails = async () => {
        await axios.get(`${environment.baseUrl}user-details/${EmpId}`)
            .then(res => {
                setUserDetails(res.data);
            })
            .catch(res => console.log("error"))
    }
    const ExamplePDFViewer = (file: any) => {
        window.open(`${environment.imageUrl}${file}`);
    };
    useEffect(() => {
        GetRewards(),
            EmployeeDetails()
    }, [update])

    return (
        < >
        <div className="container-fluid">
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="d-sm-flex align-items-center justify-content-between mb-3">
                    <h1 className="h3 mb-0 base-text-color base-font-bold pt-2 ps-3"> Rewards </h1>
                    <RewardsModal update={setUpdate} />
                </div>
                <table className="table table-hover">
                    <thead key="thead">
                        <tr >
                            <th style={{ color: "black", fontWeight: 500, fontSize: 18 }} scope="col">EmpId</th>
                            <th style={{ color: "black", fontWeight: 500, fontSize: 18 }} scope="col">Document</th>
                            <th style={{ color: "black", fontWeight: 500, fontSize: 18 }} scope="col">Upload Date</th>
                            <th style={{ color: "black", fontWeight: 500, fontSize: 18 }} scope="col">Word Count</th>
                            <th style={{ color: "black", fontWeight: 500, fontSize: 18 }} scope="col">Points</th>
                        </tr>
                    </thead>
                    <tbody key="tbody">
                        {rewards && rewards?.map((reward: any) => {
                            return (

                                <tr>
                                    <td style={{ fontWeight: 500, fontSize: 15 }}>{reward.employee_id}</td>
                                    <td style={{ fontWeight: 500, fontSize: 15 }}><Link onClick={() => ExamplePDFViewer(reward.file)} to=''>View</Link></td>
                                    <td style={{ fontWeight: 500, fontSize: 15 }} >{moment(reward.created_date).format('DD/MM/YYYY')}</td>
                                    <td style={{ fontWeight: 500, fontSize: 15 }}>{reward.doc_info == 'null' ? <span>no count</span> : reward.doc_info}</td>
                                    {/* <td style={{ fontWeight: 500, fontSize: 15 }}>{reward.doc_info / 2 == NaN ? <span>Hello</span> : reward.doc_info / 2}</td> */}
                                    {/* <td><Link onClick={() =>ViewPdf(document.file)} to=''>View</Link></td>  */}
                                </tr>

                            )
                        })}
                        {/* <td></td><td></td><td></td><td style={{ fontWeight: 500, fontSize: 15, color: "black" }}>Total</td><td style={{ fontWeight: 500, fontSize: 15, color: "black" }}>{userDetails.rewards}</td> */}

                    </tbody>

                </table>
            </div>
            </div>
        </>
    )
}

export default RewardsPage