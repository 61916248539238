import React, { useEffect, useState } from 'react'
import './attendance_css/sb-admin-2.css'
import 'font-awesome/css/font-awesome.min.css';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AddDocument from './addDocumentModel';
import { environment } from '../../env';
// import kamlesh from '../../pdf/kamlesh-JoiningLetter.pdf';
import PDFViewer from 'pdf-viewer-reactjs'
import { NavLink } from 'react-router-dom';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

interface UploadDocumentData {
    employee_id: any,
    doc_info: any,
    created_date: any,
    file: any
}

const UserDocuments = () => {
    const EmpId = localStorage.getItem('employee_id')
    const [uploadDoc, setUploadDoc] = useState<UploadDocumentData[]>([])
    const [update, setUpdate] = useState(false);

    const GetUploadDocument = async () => {
        await axios.get(`${environment.baseUrl}get-documents/${EmpId}`).then(res => {
            setUploadDoc(res.data)

        })
            .catch(res => console.log("Upload doc error"))
    };

    const ExamplePDFViewer = (file: any) => {
        window.open(`${environment.imageUrl}${file}`);
    };

    useEffect(() => {
        GetUploadDocument()
    }, [update])

    return (
        < >
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }} className='mt-3'>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="fs-3 mb-0  base-font-600 base-text-color">Employee Documents</h1>

                    <AddDocument update={setUpdate} />
                </div>
                <table className="table table-hover">
                    <thead key="thead">
                        <tr >
                            <th className='Experience-th fs-14' scope="col">EmpId</th>
                            <th className='Experience-th fs-14' scope="col">Document Name</th>
                            <th className='Experience-th fs-14' scope="col">Upload Date</th>
                            <th className='Experience-th fs-14' scope="col">View File</th>

                        </tr>
                    </thead>
                    <tbody key="tbody">
                        {uploadDoc && uploadDoc?.map((document: any) => {
                            return (
                                <tr>
                                    <td style={{ fontWeight: 500, fontSize: 15 }}>{document.employee_id}</td>
                                    <td style={{ fontWeight: 500, fontSize: 15 }}>{document.doc_info}</td>
                                    <td style={{ fontWeight: 500, fontSize: 15 }} >{moment(document.created_date).format('DD/MM/YYYY')}</td>
                                    {/* <td><Link onClick={() =>ViewPdf(document.file)} to=''>View</Link></td> */}
                                    <td style={{ fontWeight: 500, fontSize: 15 }}><Link onClick={() => ExamplePDFViewer(document.file)} to=''>View</Link></td>

                                </tr>
                            )
                        })}


                    </tbody>

                </table>
            </div>
        </>
    )
}

export default UserDocuments
