import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Provider } from "react-redux"
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import store, { persistor } from './store';
import addNotification from 'react-push-notification';
import logo from './image/logo.png'

const notifications = () =>{
  console.log("gdfgfdgdr");
  
  addNotification({
    title:"TechieBears Attendance",
    message: 'Kindly logout, if you have completed your working hours.',
    duration: 60000,
    icon: logo,
    native: true,
    
    
  });
}
let date = new Date().setHours(17,50,0,0);
let date1 = new Date().setHours(19,30,0,0);
let nowDate = new Date().getTime()
const timeduration = date - nowDate
const timeduration1 = date1 - nowDate
setTimeout(()=>{
notifications()},timeduration)
setTimeout(()=>{
notifications()},timeduration1)









const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
