import React, { useEffect, useState } from 'react'
import 'font-awesome/css/font-awesome.min.css';
import axios from 'axios';
import moment from 'moment';
import ProfileModal from './ProfileModal';
import { Button } from '@mui/material';
import Select from 'react-select'
import { Card, Col, CardBody } from 'reactstrap';
import Mispunch from './pages/mispunch';
import { json } from 'stream/consumers';
import RewardsModal from './pages/rewardsModal';
import { environment } from '../env';

interface UserData {
    firstname: string;
    lastname: string;
    profile_pic: any;

}

interface UserData2 {
    date: any;
    day: any;
    login_time: any;
    logout_time: any;
    comment: any;
    remark: any
}

const Mispunchdetails = () => {
    const [posts, setPost] = useState<UserData2[]>([]);
    const [updateMispunch, setUpdateMispunch] = useState(false);
    const [userDetails, setUserDetails] = useState<UserData>({
        firstname: '',
        lastname: '',
        profile_pic: '',
    });
    let EmpId = localStorage.getItem("employee_id");
    //      =======================================Get Single User Data =======================================
    const EmployeeDetails = () => {
        axios.get(`${environment.baseUrl}user-details/${EmpId}`)
            .then(res => {
                setUserDetails(res.data);
            })
            .catch(res => console.log("error"))
    }
    const GetMisPunch = () => {
        axios.get(`${environment.baseUrl}mispunch-by-id/${EmpId}`)
            .then(res => {
                setPost(res.data);
            })
            .catch(res => console.log("error"));
    };
    useEffect(() => {
        EmployeeDetails()
        GetMisPunch()
    }, []);

    useEffect(()=>{
        GetMisPunch()
    },[updateMispunch]);

    return (
        <>

            <div id="content-wrapper" className="d-flex flex-column">

                <div id="content">


                    <div className="container-fluid">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 base-text-color base-font-bold pt-2 ps-3" >Mispunch Details</h1>

                            <Mispunch post={EmpId} update = {setUpdateMispunch} />
                        </div>
                       
                                    <table className="table table-hover">
                                        <thead style={{ color: "black", fontWeight: 500, fontSize: 18 }}key="thead">
                                            <tr>
                                                <th scope="col">
                                                    Date
                                                </th>
                                                <th scope="col">Day</th>
                                                <th scope="col">Time-In</th>
                                                <th scope="col">Time-Out</th>
                                                <th scope="col">Comments</th>
                                                <th scope="col">Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ fontWeight: 500, fontSize: 15 }} key="tbody">
                                            {posts && posts?.map((post: any) => {
                                                return (
                                                    <tr key={post.id}>
                                                        <th scope="row" >{post.date}</th>
                                                        <td >{moment(post.day).format('dddd')}</td>
                                                        <td>{moment(post.login_time.split("T")[1], "HH:mm:ss").format('hh:mm A')}</td>
                                                        <td>{moment(post.logout_time.split("T")[1], "HH:mm:ss").format('hh:mm A')}</td>
                                                        <td ><div className="comments-scroll">
                                                        {post.comment}
                                                            </div></td>
                                                        <td >{post.remark}</td>
                                                    </tr>

                                                )

                                            })}

                                        </tbody>

                                    </table>
                            

                    </div>

                </div>

            </div>

        </>

    )
}

export default Mispunchdetails