export const environment = {
  // --------------------Local Api Url--------------------

  // baseUrl: 'http://127.0.0.1:8000/',
  // imageUrl: 'http://127.0.0.1:8000',
  // ----------------------------------------------------


  // --------------------Live Api Url--------------------
  baseUrl: 'http://15.206.189.106/',
  imageUrl: 'http://15.206.189.106',
  // ----------------------------------------------------


}