import React, { useEffect, useState } from 'react'
import 'font-awesome/css/font-awesome.min.css';
import axios from 'axios';
import { environment } from '../../../env';
import { Link } from 'react-router-dom';
import SalarySlip from './SalarySlip';

interface UploadDocumentData {
    employee_id: any,
    doc_info: any,
    created_date: any,
    file: any
}

const UserSalarySlipTable = () => {
    const userid = localStorage.getItem('userid')
    const [salaryslip, setSalarySlip] = useState<UploadDocumentData[]>([])
    const [update, setUpdate] = useState(false);

    const GetUploadDocument = async () => {
        await axios.get(`${environment.baseUrl}get-salaryslip/${userid}`).then(res => {
          setSalarySlip(res.data)

        })
            .catch(res => console.log("error"))
    };

    const ExamplePDFViewer = (file: any) => {
        window.open(`${environment.imageUrl}${file}`);
    };

    useEffect(() => {
        GetUploadDocument()
    }, [update])

    return (
        < >
        <div className="container-fluid">
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 base-text-color base-font-bold pt-3">Salary Slip</h1>
                </div>
                <table className="table table-hover">
                    <thead key="thead">
                        <tr >
                            <th style={{ color: "black", fontWeight: 500, fontSize: 18 }} scope="col">Id</th>
                            <th style={{ color: "black", fontWeight: 500, fontSize: 18 }} scope="col">Month</th>
                            <th style={{ color: "black", fontWeight: 500, fontSize: 18 }} scope="col">View File</th>

                        </tr>
                    </thead>
                    <tbody key="tbody">
                        {salaryslip && salaryslip?.map((salary: any) => {
                            return (
                                <tr>
                                    <td style={{ fontWeight: 500, fontSize: 15 }}>{salary.id}</td>
                                    <td style={{ fontWeight: 500, fontSize: 15 }}>{salary.payslip_month}</td>
                                    <td style={{ fontWeight: 500, fontSize: 15 }}><Link  to='#'><SalarySlip/></Link></td>
                                    {/* <td style={{ fontWeight: 500, fontSize: 15 }}><Link  to='#'><SalarySlip/></Link></td> */}

                                </tr>
                            )
                        })}


                    </tbody>

                </table>
            </div>
            </div>
        </>
    )
}

export default UserSalarySlipTable



